import { Col } from "antd"
import { RowProps } from "antd/lib"
import React from "react"
import { useTranslation } from "react-i18next"
import { theme } from "../../theme"
import { Tag } from "../Tag/Tag"
import { StyledRow } from "./Gird.style"

export interface GridProps extends RowProps {
  className?: string
  gridClassName?: string
  background?: boolean | string
  children?: React.ReactNode
  padding?: string
  alignItems?: string
  width?: string
  borderRight?: string
  borderLeft?: string
  labelContent?: string | React.ReactNode
  required?: boolean
  labelSpan?: number
  labelAlignItems?: string
  justifyContent?: string
  textAlign?: "left" | "center" | "right" | "justify"
  fontWeight?: number
  borderRadius?: string
  extra?: React.ReactNode
}

const Grid: React.FC<GridProps> = ({
  className,
  background,
  labelContent,
  children,
  padding,
  alignItems,
  width,
  borderRight,
  borderLeft,
  required,
  labelSpan,
  labelAlignItems,
  justifyContent,
  textAlign,
  fontWeight,
  gridClassName,
  borderRadius,
  extra,
}) => {
  const { t } = useTranslation()
  return (
    <StyledRow
      className={`${className} ${gridClassName}`}
      style={{
        background: background
          ? background === true
            ? theme.colors.disabled
            : background
          : theme.colors.white,
      }}
      padding={padding}
      alignItems={alignItems}
      width={width}
      borderRight={borderRight}
      borderLeft={borderLeft}
      labelAlignItems={labelAlignItems}
      justifyContent={justifyContent}
      textAlign={textAlign || "center"}
      fontWeight={fontWeight || 400}
      borderRadius={borderRadius}
    >
      <Col
        className={`col__content label ${className}`}
        style={{
          background: background ? theme.colors.disabled : "",
        }}
        sm={{
          span: 24,
        }}
        lg={{
          span: labelSpan || 4,
        }}
      >
        <span id={"label__text"}> {labelContent}</span>
        {required && (
          <Tag
            title={t("Required")}
            color={theme.colors.error}
            fontSize={"10px"}
            padding={"2px"}
          />
        )}
        {extra && extra}
      </Col>

      <Col
        className={"col__content value"}
        sm={{
          span: 24,
        }}
        lg={{
          span: labelSpan ? 24 - labelSpan : 20,
        }}
      >
        {children}
      </Col>
    </StyledRow>
  )
}
Grid.displayName = "Grid"
export { Grid }
