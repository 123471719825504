import { notification } from "antd"
import { ADDITIONAL_ITEM_VALUES, SERVICE_TYPE_VALUES } from "./constants"

export const catchExceptionCallback = (error: any) => {
  let error1 = error
  if (error?.response && error?.response?.data) {
    if (error?.response?.data?.message) {
      error1 = error.response.data.message
    } else {
      error1 = error.response.data
    }
  } else if (error?.data) {
    error1 = error?.data?.error
  } else if (error?.message) {
    error1 = error?.message
  }
  notification.error({
    message: error1 || "Something went wrong. Please contact administrator.",
    className: "login-error-notification",
  })
  return error
}

export const MATCHED_ITEMS = {
  VISIT_SPECIAL_SUPPORT: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES.VISIT_SPECIAL_SUPPORT,
      additional_item_value: "NO",
      additional_item_value2: false,
      start_time_hr: "",
      start_time_min: "",
      end_time_hr: "",
      end_time_min: "",
      furigana: null,
    },
  ],
  TRIAL_USE_SUPPORT: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES?.TRIAL_USE_SUPPORT,
      additional_item_value: "NO",
      additional_item_value2: false,
      furigana: null,
    },
  ],
  ABSENCE_ADDITION: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES?.ABSENCE_ADDITION,
      additional_item_value: "NO",
      additional_item_value2: false,
      furigana: null,
    },
  ],
  SOCIAL_LIFE_SUPPORT_SPECIAL: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES?.SOCIAL_LIFE_SUPPORT_SPECIAL,
      additional_item_value: "NO",
      additional_item_value2: false,
      furigana: null,
    },
  ],
  SUPPORT_FOR_LIVING_AT_HOME: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES.SUPPORT_FOR_LIVING_AT_HOME,
      additional_item_value: "NO",
      additional_item_value2: false,
      furigana: null,
    },
  ],
  MEDICAL_COOPERATION_SYSTEM: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES.MEDICAL_COOPERATION_SYSTEM,
      additional_item_value: "NO",
      additional_item_value2: false,
      furigana: null,
    },
  ],
  INTENSIVE_SUPPORT: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT,
      additional_item_value: "NO",
      additional_item_value2: false,
      furigana: null,
    },
  ],
  EMERGENCY_ACCEPTANCE: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES.EMERGENCY_ACCEPTANCE,
      additional_item_value: "NO",
      additional_item_value2: false,
      furigana: null,
    },
  ],
  INITIAL_ADDITION: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES.INITIAL_ADDITION,
      additional_item_value: "NO",
      additional_item_value2: false,
      furigana: null,
    },
  ],
  USER_BURDEN_LIMIT_MANAGEMENT: [
    {
      user_id: null,
      additional_item: ADDITIONAL_ITEM_VALUES.USER_BURDEN_LIMIT_MANAGEMENT,
      additional_item_value: "NO",
      additional_item_value2: false,
      furigana: null,
    },
  ],
}

export const ADDITIONAL_ITEM_TYPES = (service_type) => {
  if (service_type == 1) {
    return {
      ...MATCHED_ITEMS,
      EMPLOYMENT_TRANSITION_COOPERATION: [
        {
          user_id: null,
          additional_item:
            ADDITIONAL_ITEM_VALUES.EMPLOYMENT_TRANSITION_COOPERATION,
          additional_item_value: "NO",
          additional_item_value2: false,
          furigana: null,
        },
      ],
    }
  } else if (service_type == 2) {
    return {
      ...MATCHED_ITEMS,
      REGIONAL_COLLABORATION_ADDITION: [
        {
          user_id: null,
          additional_item:
            ADDITIONAL_ITEM_VALUES?.REGIONAL_COLLABORATION_ADDITION,
          additional_item_value: "NO",
          additional_item_value2: false,
          furigana: null,
        },
      ],
      PEER_SUPPORT: [
        {
          user_id: null,
          additional_item: ADDITIONAL_ITEM_VALUES.PEER_SUPPORT,
          additional_item_value: "NO",
          additional_item_value2: false,
          furigana: null,
        },
      ],
      EMPLOYMENT_TRANSITION_COOPERATION: [
        {
          user_id: null,
          additional_item:
            ADDITIONAL_ITEM_VALUES.EMPLOYMENT_TRANSITION_COOPERATION,
          additional_item_value: "NO",
          additional_item_value2: false,
          furigana: null,
        },
      ],
    }
  } else if (service_type == 3) {
    return {
      ...MATCHED_ITEMS,
      REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION: [
        {
          user_id: null,
          additional_item:
            ADDITIONAL_ITEM_VALUES.REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION,
          additional_item_value: "NO",
          additional_item_value2: false,
          furigana: null,
        },
      ],
      MENTALLY_DISABLED_DISCHARGE_SUPPORT: [
        {
          user_id: null,
          additional_item:
            ADDITIONAL_ITEM_VALUES.MENTALLY_DISABLED_DISCHARGE_SUPPORT,
          additional_item_value: "NO",
          additional_item_value2: false,
          furigana: null,
        },
      ],
      TRANSITION_PREPARATION_SUPPORT: [
        {
          user_id: null,
          additional_item:
            ADDITIONAL_ITEM_VALUES.TRANSITION_PREPARATION_SUPPORT,
          additional_item_value: "NO",
          additional_item_value2: false,
          furigana: null,
        },
      ],
      COMMUTING_TRAINING: [
        {
          user_id: null,
          additional_item: ADDITIONAL_ITEM_VALUES.COMMUTING_TRAINING,
          additional_item_value: "NO",
          additional_item_value2: false,
          furigana: null,
        },
      ],
    }
  }
}

export const EMPLOYMENT_TYPE_A_FACILITY = [
  {
    additional_item: "WELFARE_SPECIALIST_PLACEMENT",
    additional_item_value: "NO_CHANGE",
    label: "Addition for welfare specialist staff placement",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "I", value: "I" },
      { label: "II", value: "II" },
      { label: "III", value: "III" },
    ],
  },
  {
    additional_item: "EMPLOYMENT_TRANSITION_SUPPORT",
    additional_item_value: "NO_CHANGE",
    label: "Employment transition support system addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "SEVERELY_ILL_PERSON_SUPPORT",
    additional_item_value: "NO_CHANGE",
    label: "Severely ill person support addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Severely ill person support addition(I)", value: "I" },
      { label: "Severely ill person support addition(II)", value: "II" },
    ],
  },
  {
    additional_item: "WAGE_IMPROVEMENT_ACHIEVEMENT_INSTRUCTOR_PLACEMENT",
    additional_item_value: "NO_CHANGE",
    label: "Wage improvement achievement instructor placement addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "TRANSPORT_ADDITION",
    additional_item_value: "NO_CHANGE",
    label: "Transportation addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Addition of transportation ( I )", value: "I" },
      { label: "Addition of transportation ( II )", value: "II" },
    ],
  },
  {
    additional_item: "MEAL_ADDITION",
    additional_item_value: "NO_CHANGE",
    label: "Meal addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "SUPPORT_FOR_VISUAL_AUDITORY_DISABILITIES",
    additional_item_value: "NO_CHANGE",
    label:
      "Support system for people with visual and auditory language disabilities addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      {
        label:
          "Support system for people with visual and auditory language disabilities addition ( I )",
        value: "I",
      },
      {
        label:
          "Support system for people with visual and auditory language disabilities addition ( II )",
        value: "II",
      },
    ],
  },
  {
    additional_item: "HIGHER_BRAIN_DYSFUNCTION_SUPPORT",
    additional_item_value: "NO_CHANGE",
    label: "People with higher brain dysfunction support addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "SERVICE_PROVISION_STAFF_LACK",
    additional_item_value: "NO_CHANGE",
    label: "Service provision staff lack subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Up to 2 months", value: "UPTO_2_MONTHS" },
      { label: "After 3 months", value: "AFTER_3_MONTHS" },
    ],
  },
  {
    additional_item: "SERVICE_MANAGER_LACK",
    additional_item_value: "NO_CHANGE",
    label: "Service manager lack subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Up to 4 months", value: "UPTO_4_MONTHS" },
      { label: "After 5 months", value: "AFTER_5_MONTHS" },
    ],
  },
  {
    additional_item: "EMPLOYMENT_SUPPORT_BY_LOCAL_GOV",
    additional_item_value: "NO_CHANGE",
    label:
      "In case of A-type employment support facilities established by local government",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "EXCESS_CAPACITY",
    additional_item_value: "NO_CHANGE",
    label: "Excess capacity subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "UNPUBLISHED_SELF_EVALUATION",
    additional_item_value: "NO_CHANGE",
    label: "Self-evaluation unpublished subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "NON_IMPLEMENTATION_OF_ABUSE_PREVENTION",
    additional_item_value: "NO_CHANGE",
    label: "Non-implementation of abuse prevention measures subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "NON_IMPLEMENTATION_PHYSICAL_RESTRAINT",
    additional_item_value: "NO_CHANGE",
    label: "Physical restraint abolition non-implementation subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "NON_ESTABLISHED_BUSINESS_CONTINUATION_PLAN",
    additional_item_value: "NO_CHANGE",
    label: "Business continuity plans not established subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "UNREPORTED_INFORMATION_DISCLOSURE",
    additional_item_value: "NO_CHANGE",
    label: "Information disclosure unreported subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
]
export const EMPLOYMENT_TYPE_B_FACILITY = [
  {
    additional_item: "WELFARE_SPECIALIST_PLACEMENT",
    additional_item_value: "NO_CHANGE",
    label: "Addition for welfare specialist staff placement",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "I", value: "I" },
      { label: "II", value: "II" },
      { label: "III", value: "III" },
    ],
  },
  {
    additional_item: "EMPLOYMENT_TRANSITION_SUPPORT",
    additional_item_value: "NO_CHANGE",
    label: "Employment transition support system addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "SEVERELY_ILL_PERSON_SUPPORT",
    additional_item_value: "NO_CHANGE",
    label: "Severely ill person support addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Severely ill person support addition(I)", value: "I" },
      { label: "Severely ill person support addition(II)", value: "II" },
    ],
  },
  {
    additional_item: "TARGET_WAGE_ACHIEVEMENT_INSTRUCTOR",
    additional_item_value: "NO_CHANGE",
    label: "Target wage achievement instructor addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "TARGET_WAGE_ACHIEVEMENT",
    additional_item_value: "NO_CHANGE",
    label: "Target wage achievement addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "TRANSPORT_ADDITION",
    additional_item_value: "NO_CHANGE",
    label: "Transportation addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Addition of transportation ( I )", value: "I" },
      { label: "Addition of transportation ( II )", value: "II" },
    ],
  },
  {
    additional_item: "MEAL_ADDITION",
    additional_item_value: "NO_CHANGE",
    label: "Meal addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "SUPPORT_FOR_VISUAL_AUDITORY_DISABILITIES",
    additional_item_value: "NO_CHANGE",
    label:
      "Support system for people with visual and auditory language disabilities addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      {
        label:
          "Support system for people with visual and auditory language disabilities addition ( I )",
        value: "I",
      },
      {
        label:
          "Support system for people with visual and auditory language disabilities addition ( II )",
        value: "II",
      },
    ],
  },
  {
    additional_item: "HIGHER_BRAIN_DYSFUNCTION_SUPPORT",
    additional_item_value: "NO_CHANGE",
    label: "People with higher brain dysfunction support addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  //subtraction
  {
    additional_item: "SERVICE_PROVISION_STAFF_LACK",
    additional_item_value: "NO_CHANGE",
    label: "Service provision staff lack subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Up to 2 months", value: "UPTO_2_MONTHS" },
      { label: "After 3 months", value: "AFTER_3_MONTHS" },
    ],
  },
  {
    additional_item: "SERVICE_MANAGER_LACK",
    additional_item_value: "NO_CHANGE",
    label: "Service manager lack subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Up to 4 months", value: "UPTO_4_MONTHS" },
      { label: "After 5 months", value: "AFTER_5_MONTHS" },
    ],
  },
  {
    additional_item: "EMPLOYMENT_SUPPORT_BY_LOCAL_GOV",
    additional_item_value: "NO_CHANGE",
    label:
      "In case of B-type employment support facilities established by local government",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "EXCESS_CAPACITY",
    additional_item_value: "NO_CHANGE",
    label: "Excess capacity subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "SHORT_TIME_USE_SUBTRACTION",
    additional_item_value: "NO_CHANGE",
    label: "Short-time use subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "NON_IMPLEMENTATION_OF_ABUSE_PREVENTION",
    additional_item_value: "NO_CHANGE",
    label: "Non-implementation of abuse prevention measures subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "NON_IMPLEMENTATION_PHYSICAL_RESTRAINT",
    additional_item_value: "NO_CHANGE",
    label: "Physical restraint abolition non-implementation subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "NON_ESTABLISHED_BUSINESS_CONTINUATION_PLAN",
    additional_item_value: "NO_CHANGE",
    label: "Business continuity plans not established subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "UNREPORTED_INFORMATION_DISCLOSURE",
    additional_item_value: "NO_CHANGE",
    label: "Information disclosure unreported subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
]

export const EMPLOYMENT_TYPE_C_FACILITY = [
  {
    additional_item: "WELFARE_SPECIALIST_PLACEMENT",
    additional_item_value: "NO_CHANGE",
    label: "Addition for welfare specialist staff placement",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "I", value: "I" },
      { label: "II", value: "II" },
      { label: "III", value: "III" },
    ],
  },
  {
    additional_item: "TRANSPORT_ADDITION",
    additional_item_value: "NO_CHANGE",
    label: "Transportation addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Addition of transportation ( I )", value: "I" },
      { label: "Addition of transportation ( II )", value: "II" },
    ],
  },
  {
    additional_item: "MEAL_ADDITION",
    additional_item_value: "NO_CHANGE",
    label: "Meal addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "EMPLOYMENT_SUPPORT_RELATED_TRAINING",
    additional_item_value: "NO_CHANGE",
    label: "Employment support related training completion addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "SUPPORT_FOR_VISUAL_AUDITORY_DISABILITIES",
    additional_item_value: "NO_CHANGE",
    label:
      "Support system for people with visual and auditory language disabilities addition",
    type: "addition",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      {
        label:
          "Support system for people with visual and auditory language disabilities addition ( I )",
        value: "I",
      },
      {
        label:
          "Support system for people with visual and auditory language disabilities addition ( II )",
        value: "II",
      },
    ],
  },
  //subtraction
  {
    additional_item: "SERVICE_PROVISION_STAFF_LACK",
    additional_item_value: "NO_CHANGE",
    label: "Service provision staff lack subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Up to 2 months", value: "UPTO_2_MONTHS" },
      { label: "After 3 months", value: "AFTER_3_MONTHS" },
    ],
  },
  {
    additional_item: "SERVICE_MANAGER_LACK",
    additional_item_value: "NO_CHANGE",
    label: "Service manager lack subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Up to 4 months", value: "UPTO_4_MONTHS" },
      { label: "After 5 months", value: "AFTER_5_MONTHS" },
    ],
  },
  {
    additional_item: "EMPLOYMENT_SUPPORT_BY_LOCAL_GOV",
    additional_item_value: "NO_CHANGE",
    label:
      "In case of transition employment support facilities established by local government",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "EXCESS_CAPACITY",
    additional_item_value: "NO_CHANGE",
    label: "Excess capacity subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "EXCEEDING_STANDARD_USAGE_PERIOD",
    additional_item_value: "NO_CHANGE",
    label: "Exceeding standard usage period subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },

  {
    additional_item: "NON_IMPLEMENTATION_OF_ABUSE_PREVENTION",
    additional_item_value: "NO_CHANGE",
    label: "Non-implementation of abuse prevention measures subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "NON_IMPLEMENTATION_PHYSICAL_RESTRAINT",
    additional_item_value: "NO_CHANGE",
    label: "Physical restraint abolition non-implementation subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "NON_ESTABLISHED_BUSINESS_CONTINUATION_PLAN",
    additional_item_value: "NO_CHANGE",
    label: "Business continuity plans not established subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
  {
    additional_item: "UNREPORTED_INFORMATION_DISCLOSURE",
    additional_item_value: "NO_CHANGE",
    label: "Information disclosure unreported subtraction",
    type: "subtraction",
    options: [
      { label: "No change", value: "NO_CHANGE" },
      { label: "No", value: "NO" },
      { label: "Yes", value: "YES" },
    ],
  },
]

export const ADDITIONAL_FACILITY = (employment_type) => {
  if (employment_type == SERVICE_TYPE_VALUES.TYPE_1) {
    return [...EMPLOYMENT_TYPE_A_FACILITY]
  }
  if (employment_type == SERVICE_TYPE_VALUES?.TYPE_2) {
    return [...EMPLOYMENT_TYPE_B_FACILITY]
  }
  if (employment_type == SERVICE_TYPE_VALUES?.TRANSITION) {
    return [...EMPLOYMENT_TYPE_C_FACILITY]
  }
}
