export const scrollToFirstErrorField = (errors) => {
  if (Object.keys(errors).length === 0) return
  const firstErrorKey = Object.keys(errors)[0]
  const firstErrorDocument = document.getElementsByName(firstErrorKey)
  if (firstErrorDocument.length > 0)
    firstErrorDocument[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  else {
    const errorDocumentFromId = document.getElementById(firstErrorKey)
    if (errorDocumentFromId)
      errorDocumentFromId.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
  }
}
export const scrollToFirstUserErrorField = (errors) => {
  if (Object.keys(errors).length === 0) return
  const firstErrorKey = Object.keys(errors)[0]
  const firstErrorDocument = document.getElementsByName(firstErrorKey)
  if (typeof errors[firstErrorKey] === "object") {
    scrollToFirstErrorField(errors[firstErrorKey])
  } else if (firstErrorDocument.length > 0)
    firstErrorDocument[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  else {
    const errorDocumentFromId = document.getElementById(firstErrorKey)
    if (errorDocumentFromId)
      errorDocumentFromId.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
  }
}

export const scrollToFirstErrorByContent = (textMatcher) => {
  // Not the tidiest approach but its much much simpler to use
  // (and you don't have to rely on formik's error state)

  // Most importantly, it works so 🤷🏽‍♂️
  const errorDocument = Array.from(document.querySelectorAll("div")).find(
    (el) => textMatcher(el.textContent),
  )
  if (errorDocument)
    errorDocument.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
}
