import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"

// packages
import { App } from "antd"
import { QueryClient, useQuery } from "react-query"

// services
import { fetchCompany, fetchWorkMasterData } from "../services"

// commons
import { InitialLoader } from "@project/common"

// types
import { FacilityMe, Me, UserInfo } from "../types"

export interface ReloadUserInfo {
  localId: string
  email: string
  passwordHash: string
  emailVerified: boolean
  passwordUpdatedAt: number
  providerUserInfo: ProviderUserInfo[]
  validSince: string
  disabled: boolean
  lastLoginAt: string
  createdAt: string
  customAttributes: string
  tenantId: string
  lastRefreshAt: string
}

export interface ProviderUserInfo {
  providerId: string
  federatedId: string
  email: string
  rawId: string
}

type ContextProps = {
  loading: boolean
  user: any | null
  authenticated: boolean
  setUser: any
  isOwner: boolean
  logOut: any
  facilities: FacilityMe["data"]
  userInformation: Me
  companyInfo?: any
  isInfoLoading?: boolean
  queryClient?: QueryClient
  workData?: any
}

export const AuthContext = createContext<Partial<ContextProps>>({})

// TODO: Added values/props as required during actual integration.
type AuthProviderProps = {
  loading: boolean
  user: any | null
  isOwner: boolean
  children: React.ReactNode
  logOut: any
  facilities: FacilityMe["data"]
  userInformation: Me
  isInfoLoading?: boolean
  queryClient?: QueryClient
  setUser: Dispatch<SetStateAction<UserInfo>>
  workData?: any
}

export const AuthProvider = (props: AuthProviderProps) => {
  const {
    user,
    loading,
    setUser,
    isOwner,
    children,
    facilities,
    queryClient,
    userInformation,
    logOut,
    isInfoLoading,
  } = props

  const [isClient, setIsClient] = useState(false)

  const { data: companyInfo } = useQuery(
    ["fetch-company-info"],
    () => fetchCompany(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      enabled: userInformation?.my_page_setting?.footer_company_info_flg
        ? true
        : false,
      select: ({ data }) => {
        return {
          company_name: data?.company_name,
          fax_no: data?.fax_no,
          tel_no: data?.tel_no,
          zip_code: data?.zip_code,
          address: data?.address,
        }
      },
    },
  )
  const { data: workData } = useQuery({
    queryKey: ["fetch-work-master-info"],
    queryFn: () => fetchWorkMasterData(),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 60 * 1000 * 2,
    retry: 1,
    select: (res) => {
      return res?.data?.map((work) => ({
        label: work?.work_name,
        value: `${work?.id}`,
      }))
    },
  })
  useEffect(() => {
    setIsClient(true)
  }, [])
  return (
    <AuthContext.Provider
      value={{
        user,
        logOut,
        setUser,
        isOwner,
        facilities,
        companyInfo,
        queryClient,
        isInfoLoading,
        userInformation,
        loading: loading,
        authenticated: user !== null,
        workData: workData,
      }}
    >
      {isClient ? (
        <App>{children}</App>
      ) : (
        <InitialLoader
          logoImg={
            <img
              src={"./assets/logo.svg"}
              height={60}
              width={60}
              alt={"logo"}
            />
          }
        />
      )}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthProvider")
  }
  return context
}
