import { Input } from "antd"
import { InputProps, TextAreaProps } from "antd/es/input"
import TextArea from "antd/lib/input/TextArea"
import React from "react"
import styled from "styled-components"
import { theme } from "../../theme"
import { CharacterCounter } from "../../utils"
import { ErrorMassage } from "../ErrorMassage/ErrorMassage"

export interface Props {
  bg?: string
  padding?: string
  height?: string
  width?: string
  borderColor?: string
  borderRadius?: string
  error?: string
  label?: string
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  showCounting?: number
  info?: string
  subInfo?: string
  customError?: boolean
  rows?: number
  unit?: string
  unitStyle?: React.CSSProperties
  resize?: boolean
  subInfoMt?: string
  subInfoMl?: string
  fixedWidth?: boolean
  reqInfo?: string
  msgType?: "info" | "error" | "subInfo"
}

//styles
const StyledInputFieldWrapper = styled.div<Props>`
  .input__content {
    display: flex;
    gap: 0.3em;
    align-items: center;
    @media screen and (max-width: 1275px) {
      flex-wrap: wrap;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: ${theme.colors["sub-text"]};
      width: max-content;
      white-space: wrap !important;
    }
    row-gap: 0.3em;
    .form__control {
      display: flex;
      flex-direction: column;
      width: ${(props) => (props?.subInfo ? "auto" : "100%")};
      label {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 500;
        color: ${theme.colors.text};
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        width: 100%;
      }
      .input {
        display: flex;
        align-items: center;
        span {
          width: max-content;
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .error__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: ${({ error }) => error && theme.colors.error};
  }
  width: 100%;
`
const StyledInputField = styled(Input)<Props>`
  border: 1px solid
    ${({ borderColor, error }) =>
      error ? theme.colors?.error : borderColor || theme.colors.border};
  height: ${(props) => props?.height || ""};
  width: ${(props) => props?.width || "100%"};
  border-radius: ${(props) => props?.borderRadius || ""};
  &::placeholder {
    color: ${theme.colors?.placeholder};
    ${theme.typography["body-regular"]};
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      font-size: 12px !important;
    }
  }

  background-color: ${(props) => props?.bg || ""};
  padding: ${(props) => props?.padding || ""};
  :hover,
  :focus {
    box-shadow: none;
    border-color: ${({ error }) =>
      error ? theme.colors?.error : theme.colors.border};
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    width: ${(props) =>
      props?.fixedWidth ? props?.width : "100% "} !important;
  }
`

//text area style
const StyledInputAreaField = styled(TextArea)<Props>`
  border: 1px solid
    ${({ borderColor, error }) =>
      error ? theme.colors?.error : borderColor || theme.colors.border};
  height: ${(props) => props?.height || ""};
  width: ${(props) => props?.width || "100%"};
  border-radius: ${(props) => props?.borderRadius || ""};
  &::placeholder {
    color: ${theme.colors?.placeholder};
    ${theme.typography["body-regular"]};
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      font-size: 12px !important;
    }
  }
  background-color: ${(props) => props?.bg || ""};
  padding: ${(props) => props?.padding || ""};
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    min-height: 60px !important;
    height: fit-content !important;
  }
  resize: ${(props) => (props?.resize ? "auto" : "none !important")};
`
const CountingStyled = styled.div<{ isRed: boolean }>`
  font-size: 12px;
  color: ${({ isRed }) => isRed && theme.colors.error};
`
const InputField = ({
  subInfo,
  unit,
  unitStyle,
  label,
  subInfoMt,
  subInfoMl,
  reqInfo,
  msgType,
  ...props
}: InputProps & Props): JSX.Element => {
  return (
    <StyledInputFieldWrapper subInfo={subInfo}>
      <div className={"input__content"}>
        <div className={"form__control"}>
          {label && <label htmlFor={props?.id}>{label}</label>}
          <div className={"input"}>
            <StyledInputField {...props} />
            {unit && <span style={unitStyle}>{unit}</span>}
          </div>
        </div>
        {(subInfo || reqInfo) && (
          <div>
            {subInfo && (
              <>
                <ErrorMassage
                  message={subInfo}
                  type={msgType || "info"}
                  mt={subInfoMt}
                  ml={subInfoMl}
                />
              </>
            )}
            {reqInfo && <span style={{ color: "#e00" }}>{reqInfo}</span>}
          </div>
        )}
      </div>

      {!props?.customError && props?.error && (
        <ErrorMassage message={props?.error} ml={subInfoMl} />
      )}
      <div className={"error__content"}>
        <div>
          {props?.info && <ErrorMassage message={props?.info} type={"info"} />}
        </div>
        {props?.showCounting && props?.maxLength && (
          /* @ts-ignore */
          <CountingStyled isRed={props?.value?.length >= props?.maxLength}>
            {`${
              props?.value ? CharacterCounter(props?.value) : 0
            }/${props?.maxLength?.toLocaleString()}`}
          </CountingStyled>
        )}
      </div>
    </StyledInputFieldWrapper>
  )
}
InputField.displayName = "InputField"
interface AreaProps {
  mt?: string
  ml?: string
  errorAndInfoPosition?: "TB" | "BT"
}
const InputAreaField = ({
  resize = true,
  ml,
  mt,
  errorAndInfoPosition = "TB",
  ...props
}: TextAreaProps & Props & AreaProps): JSX.Element => {
  return (
    <>
      <StyledInputAreaField {...props} resize={resize} />
      <div className={"error__content"}>
        {errorAndInfoPosition === "TB" && (
          <>
            {" "}
            <div>
              {props?.info && (
                <ErrorMassage
                  message={props?.info}
                  type={"info"}
                  ml={ml}
                  mt={mt}
                />
              )}
            </div>
            {props?.error && (
              <ErrorMassage message={props?.error} ml={ml} mt={mt} />
            )}
          </>
        )}
        {errorAndInfoPosition === "BT" && (
          <>
            {props?.error && (
              <ErrorMassage message={props?.error} ml={ml} mt={mt} />
            )}
            <div>
              {props?.info && (
                <ErrorMassage
                  message={props?.info}
                  type={"info"}
                  ml={ml}
                  mt={mt}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}
InputAreaField.displayName = "InputAreaField"

export { InputAreaField, InputField }
