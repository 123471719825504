import { theme } from "@project/common"
import { ThemeConfig } from "antd"

export const ConsumerThemeConfig: ThemeConfig = {
  token: {
    colorPrimary: theme.colors.cyan1,
  },
  components: {
    Button: {
      borderRadius: 8,
      controlHeightLG: 50,
      controlHeight: 40,
      fontSizeLG: 18,
      colorPrimary: theme.colors.cyan1,

      defaultColor: theme.colors.cyan1,
      defaultBorderColor: theme.colors.cyan1,
    },
    Card: {
      headerBg: theme.colors.cyan4,
      headerHeight: 51,
      paddingLG: 20,
    },
    Radio: {
      colorPrimary: theme.colors.cyan1,
    },
  },
}
