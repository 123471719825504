import { API, removeBlankAttributes } from "@project/common"

export const getOccupations = async () => API.get(`/occupations`)
export const getOneInstructor = (id: string): Promise<{ data: any }> =>
  API.get(`/instructors/${id}`)
export const fetchAllProvisionalCity = async (): Promise<any> => {
  const params = {} as any
  params.page = 1
  params.pageSize = "Infinity"
  return API.get(`/provisional-city`, {
    params,
  })
}
export const fetchInstructors = ({
  ...args
}: {
  page?: number
  pageSize?: number | string
  keyword?: string
  retirement_date?: string
  joining_date?: string
  facility?: string
  occupation?: string
  date_staff_is_active?: string
  work_style?: string
}): Promise<any> => {
  const params: any = removeBlankAttributes(args)
  if (args.facility) {
    // for multiple facility search use facilityIds
    params.facilityIds = args.facility
    delete params.facility
  }
  return API.get(`/instructors`, {
    params,
  })
}
export const getAllMealMaster = async () => API.get(`/adult-user/meal`)
export const getQualification = () =>
  API.get("/qualifications?page=1&pageSize=Infinity")
export const getOneUser = (user_id: any) => {
  if (+user_id > 0) return API.get(`/user/${user_id}`)
}
export const fetchInfinityUsers = (params: any) => {
  return API.get("/user", { params })
}
export const getConfirmedBillingFacilities = ({ year, month, facilityIds }) =>
  API.get(
    `/nhif-billings/confirmed-facilities/${year}/${month}?facilityIds=${facilityIds}`,
  )
