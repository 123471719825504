import { API, removeBlankAttributes } from "@project/common"

export const fetchChildSupport = async () => {
  const url = `/adult-user/support-plan`
  return API.get(url)
}
export const fetchChildSupportDraft = async () => {
  const URL = "/adult-user/support-plan-draft?from=draft"

  return API.get(URL)
}
export const fetchChildMonitoringDetail = async () => {
  const result = await API.get(`/user-parent/user-monitoring`)
  return result
}

export const updateIndividualSupportPlanSignature = ({
  values,
  planType,
}: {
  values: any
  planType: "draft" | "non-draft"
}) => {
  const data = removeBlankAttributes(values)
  const URL =
    planType === "draft"
      ? `/adult-user/support-plan-draft/update-signature/${values?.planId}`
      : `/adult-user/support-plan/update-signature/${values?.planId}`
  return API.patch(URL, data)
}
