// pacakges
import dayjs from "dayjs"
import { t } from "i18next"

// types
import { AnyObject } from "antd/es/_util/type"

//constants
import { FULL_DATE_FORMAT_EN } from "../../adult/constants"

/**
 * This function returns total times between two dateTime i.e. startDateTime and endDateTime
 * But before you need to pass in valid date for proper data
 * */
export const getTotalTimeInSec =
  (date: string) => (startTime?: string, endTime?: string) => {
    if (!startTime || !endTime) {
      return 0
    }
    const startedDateTime = dayjs(`${date}${startTime}`)
    const endDateTime = dayjs(`${date}${endTime}`)

    return endDateTime.diff(startedDateTime, "second")
  }

/**
 *
 * @param seconds in `number`
 * @returns time in hour: min format
 * `00hr 00mins`
 */
export const breakTimeInString = (seconds: number) => {
  let totalSeconds = seconds
  const totalHours = Math.floor(totalSeconds / (60 * 60)) // How many hours?
  totalSeconds = totalSeconds - totalHours * 60 * 60 // Pull those hours out of totalSeconds
  const totalMinutes = Math.floor(totalSeconds / 60) //With hours out this will retun minutes

  const formattedTotalBreakTime = t("{{hour}}hr {{minute}}mins", {
    hour: totalHours.toString().padStart(2, "0"),
    minute: totalMinutes.toString().padStart(2, "0"),
  })

  return formattedTotalBreakTime
}

/**
 *
 * @param seconds in `number`
 * @returns time in this format:
 * `HH:mm`
 */
export const breakTimeInStringV2 = (seconds: number) => {
  let totalSeconds = seconds
  const totalHours = Math.floor(totalSeconds / (60 * 60)) // How many hours?
  totalSeconds = totalSeconds - totalHours * 60 * 60 // Pull those hours out of totalSeconds
  const totalMinutes = Math.floor(totalSeconds / 60) //With hours out this will retun minutes

  const formattedTotalBreakTime = t("{{hour}}:{{minute}}", {
    hour: totalHours.toString().padStart(2, "0"),
    minute: totalMinutes.toString().padStart(2, "0"),
  })

  return formattedTotalBreakTime
}

export type BreakRecords = {
  break_end_time: string
  break_end_time2: string
  break_end_time3: string
  break_end_time4: string
  break_end_time5: string
  break_start_time: string
  break_start_time2: string
  break_start_time3: string
  break_start_time4: string
  break_start_time5: string
}

/**
 *
 * @param allBreakRec All 5 break time records in object format
 * @param date current valid date in `string`
 * @returns total break time in  `00hr 00mins` format.
 */
export const calcTotalBreakTime = (
  allBreakRec: AnyObject,
  date: string,
  isNew?: boolean,
) => {
  const totalTime = getSumOfAllBreakTimes(allBreakRec, date, isNew)
  return breakTimeInString(totalTime)
}

export const getSumOfAllBreakTimes = (
  allBreakRec: AnyObject,
  date: string,
  isNew?: boolean,
) => {
  let totalTime = 0
  const currentDate = dayjs(date).format(FULL_DATE_FORMAT_EN)
  const seconds = getTotalTimeInSec(currentDate)

  const startPrefix = !isNew ? "break_start_time" : "new_break_start_time"
  const endPrefix = !isNew ? "break_end_time" : "new_break_end_time"

  for (let i = 1; i <= 5; i++) {
    if (i === 1) {
      totalTime =
        totalTime +
        seconds(allBreakRec?.[startPrefix], allBreakRec?.[endPrefix])
    }
    if (i > 1) {
      totalTime =
        totalTime +
        seconds(
          allBreakRec?.[`${startPrefix}${i}`],
          allBreakRec?.[`${endPrefix}${i}`],
        )
    }
  }

  return totalTime
}
