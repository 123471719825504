import dayjs from "dayjs"
import { ADDITIONAL_ITEM_VALUES } from "../utils"

export const BUTTON_DISPLAY_LIST = [
  {
    label: "Service provision record",
    value: "service_provision_record",
  },
  { label: "Activity recode", value: "activity_record" },
  {
    label: "Service provision record sheet",
    value: "service_provision_record_sheet",
  },
  { label: "Contact facility", value: "contact_facility" },
  { label: "Mail setting", value: "mail_setting" },
  { label: "Password change", value: "password_change" },
]

export const DO_DONOT_LIST = [
  {
    label: "Do not",
    value: "0",
  },
  { label: "Do", value: "1" },
]

export const PROVISION_RECORD_LIST = [
  {
    label: "Facility",
    value: "1",
  },
  { label: "山武", value: "2" },
  {
    label: "東松山",
    value: "3",
  },
  {
    label: "(放) 宮子",
    value: "4",
  },
  { label: "(放) 小泉", value: "5" },
  { label: "本庄", value: "6" },
  { label: "吉岡", value: "7" },
  { label: "熊谷", value: "8" },
  { label: "太田", value: "9" },
  { label: "宮子", value: "10" },
  { label: "小泉", value: "11" },
]

export const FOOTER_INFORMATION_LIST = [
  {
    label: "Company information",
    value: "company-info-1",
  },
  { label: "つくば", value: "company-info-2" },
  {
    label: "山武",
    value: "company-info-3",
  },
  {
    label: "東松山",
    value: "company-info-4",
  },
  { label: "(放) 宮子", value: "company-info-5" },
  { label: "(放) 小泉", value: "company-info-6" },
  { label: "本庄", value: "company-info-16" },
  { label: "熊谷", value: "company-info-7" },
  { label: "太田", value: "company-info-8" },
  { label: "宮子", value: "company-info-9" },
  { label: "小泉", value: "company-info-10" },
  { label: "吉岡", value: "company-info-11" },
]

export const USING_LIST = [
  {
    label: "Use",
    value: 1,
  },
  { label: "Don't use", value: 0 },
]

export const YES_NO_LIST = [
  {
    label: "Yes",
    value: "1",
  },
  { label: "No", value: "0" },
]

export const MAIN_TARGET_DISABILITY = [
  { label: "Not specified", value: "1" },
  { label: "Physically disabled", value: "2" },
  { label: "intellectually disabled", value: "3" },
  { label: "Mentally disabled", value: "4" },
  { label: "Disabled child", value: "5" },
  { label: "Person with a rare disease", value: "6" },
]

export const HEADER_INFORMATION_LIST = [
  {
    label: "Don't display",
    value: 0,
  },
  { label: "Display text", value: 1 },
  { label: "Company information", value: 2 },
  { label: "Facility", value: 3 },
  { label: "山武", value: 4 },
  { label: "東松山", value: 5 },
  { label: "(放) 宮子", value: 6 },
  { label: "小泉", value: 7 },
  { label: "本庄", value: 8 },
  { label: "吉岡", value: 9 },
  { label: "熊谷", value: 10 },
  { label: "太田", value: 11 },
  { label: "宮子", value: 12 },
]
export const SHUTTLE_CAR_AFFICATED_FACILITY = [
  {
    label: "Facility",
    value: 420,
  },
  { label: "山武", value: 100 },
  {
    label: "東松山",
    value: 60,
  },
  {
    label: "(放) 宮子",
    value: 20,
  },
]
export const BUSINESS_REGISTRATION_FACILITY = [
  {
    label: "Child development support",
    value: "facility-1",
  },
  {
    label: "Day service",
    value: "facility-2",
  },
]

export const WEEK_DAYS = [
  {
    label: "Sun",
    value: "6",
  },
  {
    label: "Mon",
    value: "0",
  },
  {
    label: "Tue",
    value: "1",
  },
  {
    label: "Wed",
    value: "2",
  },
  {
    label: "Thu",
    value: "3",
  },
  {
    label: "Fri",
    value: "4",
  },
  {
    label: "Sat",
    value: "5",
  },
]

export const DIVISION = [
  { label: "Nursery school", value: 1, name: "division_nursery" },
  { label: "Day nursery", value: 1, name: "division_day_nursery" },
  {
    label: "Kindergarten",
    value: 1,
    name: "division_kindergarden",
  },
  {
    label: "Elementary school",
    value: 1,
    name: "division_elementary",
  },
  { label: "Junior high school", value: 1, name: "division_junior_nursery" },
  { label: "High school", value: 1, name: "division_high_school" },
]

export const getBusinessRegistrationYears = () => {
  const current_year = new Date().getFullYear()
  const year_options: { label: string; value: number }[] = []
  for (let i = 2015; i < current_year + 4; i++) {
    year_options.push({ label: "" + i, value: i })
  }
  return year_options
}

export const getBusinessRegistrationMonths = () => {
  const month_options: { label: string; value: number }[] = []
  for (let i = 1; i <= 12; i++) {
    month_options.push({ label: "" + i, value: i })
  }
  return month_options
}

export const BUSINESS_REGISTRATION_BULK_SERVICE = [
  { label: "中学校", value: "junior high school" },
]
export const GENDERS = ["", "Male", "Female"]
export const CALANDER_HOURS_MINUTES: { hours: any[]; minutes: any[] } = {
  hours: [
    {
      value: "",
      label: "--",
    },
    {
      value: "00",
      label: "00",
    },
    {
      value: "01",
      label: "01",
    },
    {
      value: "02",
      label: "02",
    },
    {
      value: "03",
      label: "03",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "05",
      label: "05",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "07",
      label: "07",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "09",
      label: "09",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "21",
      label: "21",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "23",
      label: "23",
    },
  ],
  minutes: [
    {
      value: "",
      label: "--",
    },
    {
      value: "00",
      label: "00",
    },
    {
      value: "01",
      label: "01",
    },
    {
      value: "02",
      label: "02",
    },
    {
      value: "03",
      label: "03",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "05",
      label: "05",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "07",
      label: "07",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "09",
      label: "09",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "21",
      label: "21",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "23",
      label: "23",
    },
    {
      value: "24",
      label: "24",
    },
    {
      value: "25",
      label: "25",
    },
    {
      value: "26",
      label: "26",
    },
    {
      value: "27",
      label: "27",
    },
    {
      value: "28",
      label: "28",
    },
    {
      value: "29",
      label: "29",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "31",
      label: "31",
    },
    {
      value: "32",
      label: "32",
    },
    {
      value: "33",
      label: "33",
    },
    {
      value: "34",
      label: "34",
    },
    {
      value: "35",
      label: "35",
    },
    {
      value: "36",
      label: "36",
    },
    {
      value: "37",
      label: "37",
    },
    {
      value: "38",
      label: "38",
    },
    {
      value: "39",
      label: "39",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "41",
      label: "41",
    },
    {
      value: "42",
      label: "42",
    },
    {
      value: "43",
      label: "43",
    },
    {
      value: "44",
      label: "44",
    },
    {
      value: "45",
      label: "45",
    },
    {
      value: "46",
      label: "46",
    },
    {
      value: "47",
      label: "47",
    },
    {
      value: "48",
      label: "48",
    },
    {
      value: "49",
      label: "49",
    },
    {
      value: "50",
      label: "50",
    },
    {
      value: "51",
      label: "51",
    },
    {
      value: "52",
      label: "52",
    },
    {
      value: "53",
      label: "53",
    },
    {
      value: "54",
      label: "54",
    },
    {
      value: "55",
      label: "55",
    },
    {
      value: "56",
      label: "56",
    },
    {
      value: "57",
      label: "57",
    },
    {
      value: "58",
      label: "58",
    },
    {
      value: "59",
      label: "59",
    },
  ],
}
export const INSTRUCTOR_AFFILIATED_ACILITY = [
  {
    label: "Facility",
    value: "facility-0",
  },
  {
    label: "山武",
    value: "facility-1",
  },
  {
    label: "東松山",
    value: "facility-2",
  },
  {
    label: "(放) 宮子",
    value: "facility-3",
  },
  {
    label: "(放) 小泉",
    value: "facility-4",
  },
  {
    label: "本庄",
    value: "facility-5",
  },
  {
    label: "吉岡",
    value: "facility-6",
  },
  {
    label: "熊谷",
    value: "facility-7",
  },
  {
    label: "太田",
    value: "facility-8",
  },
  {
    label: "宮子",
    value: "facility-9",
  },
  {
    label: "小泉",
    value: "facility-10",
  },
]
export const INSTRUCTOR_OCCUPATIONS = [
  {
    label: "管理者",
    value: "facility-11",
  },
  {
    label: "児童発達支援管理責任者",
    value: "facility-1",
  },
  {
    label: "児童指導員",
    value: "facility-4",
  },
  {
    label: "児童指導員(児童福祉事業5年以上経験)",
    value: "facility-4",
  },
  {
    label: "保育士",
    value: "facility-4",
  },
  {
    label: "保育士(児童福祉事業5年以上経験)",
    value: "facility-4",
  },
  {
    label: "理学療法士",
    value: "facility-4",
  },
  {
    label: "作業療法士",
    value: "facility-4",
  },
  {
    label: "言語聴覚士 ",
    value: "facility-4",
  },
  {
    label: "心理指導担当職員等   ",
    value: "facility-4",
  },
  {
    label: "看護職員 ",
    value: "facility-4",
  },
  {
    label: "その他",
    value: "facility-4",
  },
]

export const NUMBER_OF_STAFF = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
]

export const GRADES = [
  {
    label: "一級地",
    value: "1",
  },
  {
    label: "二級地",
    value: "2",
  },
  {
    label: "三級地",
    value: "3",
  },
  {
    label: "四級地",
    value: "4",
  },
  {
    label: "五級地",
    value: "5",
  },
  {
    label: "六級地",
    value: "6",
  },
  {
    label: "七級地",
    value: "7",
  },
  {
    label: "その他",
    value: "Others",
  },
]
export const QUALIFICATION_NAME = [
  {
    label: "児童発達支援管理責任者",
    value: "児童発達支援管理責任者",
  },
  {
    label: "児童指導員任用資格",
    value: "児童指導員任用資格",
  },
  {
    label: "社会福祉士",
    value: "社会福祉士",
  },
  { label: "介護福祉士", value: "介護福祉士" },
  { label: "精神保健福祉士", value: "精神保健福祉士" },
  { label: "公認心理師", value: "公認心理師" },
  { label: "臨床心理士", value: "臨床心理士" },
  { label: "理学療法士免許", value: "理学療法士免許" },
  { label: "作業療法士免許", value: "作業療法士免許" },
  { label: "言語聴覚士免許", value: "言語聴覚士免許" },
  { label: "心理指導担当職員等", value: "心理指導担当職員等" },
  { label: "保育士", value: "保育士" },
  { label: "看護師免許", value: "看護師免許" },
  { label: "医師免許有資格者", value: "医師免許有資格者" },
  {
    label: "強度行動障害支援者養成研修(基礎研修)修了",
    value: "強度行動障害支援者養成研修(基礎研修)修了",
  },
  {
    label: "重度訪問介護従業者養成研修行動障害支援課程修了",
    value: "重度訪問介護従業者養成研修行動障害支援課程修了",
  },
  {
    label: "行動援護従業者養成研修修了",
    value: "行動援護従業者養成研修修了",
  },
  { lable: "手話通訳士", value: "手話通訳士" },
  { label: "手話通訊者", value: "手話通訊者" },
  { label: "柔道整復師", value: "柔道整復師" },
  { label: "鍼灸師", value: "鍼灸師" },
  { label: "あん摩マッサージ指圧師", value: "あん摩マッサージ指圧師" },
  {
    label: "国立障害者リハビリテーションセンター視覚障害学科履修者",
    value: "国立障害者リハビリテーションセンター視覚障害学科履修者",
  },
  { label: "その他", value: "その他" },
]

export const MONTHS = [
  { label: "01月", value: 1 },
  { label: "02月", value: 2 },
  { label: "03月", value: 3 },
  { label: "04月", value: 4 },
  { label: "05月", value: 5 },
  { label: "06月", value: 6 },
  { label: "07月", value: 7 },
  { label: "08月", value: 8 },
  { label: "09月", value: 9 },
  { label: "10月", value: 10 },
  { label: "11月", value: 11 },
  { label: "12月", value: 12 },
]
export const WORK_TYPES = [
  {
    label: "Full time",
    value: 1,
  },
  {
    label: "Full-time/part-time",
    value: 2,
  },
  {
    label: "Part-time/full-time",
    value: 3,
  },
  {
    label: "Part time",
    value: 4,
  },
]
export const OCCUPATION_TYPES = [
  {
    label: "管理者",
    value: 1,
  },
  {
    label: "障害福祉サービス経験者",
    value: 2,
  },
  {
    label: " 指導員(その他)",
    value: 3,
  },
  {
    label: "訪問支援員",
    value: 4,
  },
  {
    label: "栄養士 調理員",
    value: 5,
  },
  {
    label: "その他",
    value: 6,
  },
]

export const DEFAULT_HOURS = {
  business_start_hour1: null,
  business_start_minute1: null,
  business_end_hour1: null,
  business_end_minute1: null,
  business_start_hour2: null,
  business_start_minute2: null,
  business_end_hour2: null,
  business_end_minute2: null,
  business_start_hour3: null,
  business_start_minute3: null,
  business_end_hour3: null,
  business_end_minute3: null,
  service_start_hour1: null,
  service_start_minute1: null,
  service_end_hour1: null,
  service_end_minute1: null,
  service_start_hour2: null,
  service_start_minute2: null,
  service_end_hour2: null,
  service_end_minute2: null,
  service_start_hour3: null,
  service_start_minute3: null,
  service_end_hour3: null,
  service_end_minute3: null,
}

export const DAYS_OPTION = [
  {
    key: 1,
    label: 1,
    value: 1,
  },
  {
    key: 2,
    label: 2,
    value: 2,
  },
  {
    key: 3,
    label: 3,
    value: 3,
  },
  {
    key: 4,
    label: 4,
    value: 4,
  },
  {
    key: 5,
    label: 5,
    value: 5,
  },
  {
    key: 6,
    label: 6,
    value: 6,
  },
  {
    key: 7,
    label: 7,
    value: 7,
  },
  {
    key: 8,
    label: 8,
    value: 8,
  },
  {
    key: 9,
    label: 9,
    value: 9,
  },
  {
    key: 10,
    label: 10,
    value: 10,
  },
  {
    key: 11,
    label: 11,
    value: 11,
  },
  {
    key: 12,
    label: 12,
    value: 12,
  },
  {
    key: 13,
    label: 13,
    value: 13,
  },
  {
    key: 14,
    label: 14,
    value: 14,
  },
  {
    key: 15,
    label: 15,
    value: 15,
  },
  {
    key: 16,
    label: 16,
    value: 16,
  },
  {
    key: 17,
    label: 17,
    value: 17,
  },
  {
    key: 18,
    label: 18,
    value: 18,
  },
  {
    key: 19,
    label: 19,
    value: 19,
  },
  {
    key: 20,
    label: 20,
    value: 20,
  },
  {
    key: 21,
    label: 21,
    value: 21,
  },
  {
    key: 22,
    label: 22,
    value: 22,
  },
  {
    key: 23,
    label: 23,
    value: 23,
  },
  {
    key: 24,
    label: 24,
    value: 24,
  },
  {
    key: 25,
    label: 25,
    value: 25,
  },
  {
    key: 26,
    label: 26,
    value: 26,
  },
  {
    key: 27,
    label: 27,
    value: 27,
  },
  {
    key: 28,
    label: 28,
    value: 28,
  },
  {
    key: 29,
    label: 29,
    value: 29,
  },
  {
    key: 30,
    label: 30,
    value: 30,
  },
  {
    key: 31,
    label: 31,
    value: 31,
  },
]
/**
 *
 *These are options for
 Facility setup form
 *
 *
 */

export const ACCEPT_NOT = [
  {
    label: "Accept",
    value: "accept",
  },
  {
    label: "Do not accept",
    value: "do-not-accept",
  },
]

export const DISPLAY_NOT = [
  {
    label: "Display",
    value: "display",
  },
  {
    label: "Do not display",
    value: "do-not-display",
  },
]

export const SET_UP_NOT = [
  {
    label: "Set up",
    value: "set-up",
  },
  {
    label: "Do not set up",
    value: "do-not-set-up",
  },
]
export const SEND_NOT = [
  {
    label: "Send",
    value: "send",
  },
  {
    label: "Do not send",
    value: "do-not-send",
  },
]
export const SET_NOT = [
  {
    label: "Set",
    value: "set",
  },
  {
    label: "Do not set",
    value: "do-not-set",
  },
]
export const USE_NOT = [
  {
    label: "Use",
    value: "use",
  },
  {
    label: "Do not use",
    value: "do-not-use",
  },
]

export const MARK_READ_ACTION = [
  {
    label: "When you press the read \nbutton",
    value: "button",
  },
  {
    label: "When you open the \ndetail page",
    value: "open-detail",
  },
  {
    label: "When you fill in your digital signature",
    value: "fill-sign",
  },
]

export const SETTING_CALENDAR = [
  {
    label: "利用サービス",
    value: "service-used",
  },
  {
    label: "施設名",
    value: "facility-name",
  },
  {
    label: "今日の活動",
    value: "today-program",
  },
  {
    label: "お弁当",
    value: "lunch-box",
  },
  {
    label: "お弁当種類",
    value: "lunch-type",
  },
  {
    label: "送迎",
    value: "pick-up",
  },
  {
    label: "お迎え希望時間",
    value: "desired-pick-up",
  },
  {
    label: "送り希望時間",
    value: "desired-see-off ",
  },
  {
    label: "お迎え場所",
    value: "pick-up-address",
  },
  {
    label: "送り場所",
    value: "see-off-address",
  },
  {
    label: "実費",
    value: "actual-cost",
  },
  {
    label: "備考",
    value: "remarks",
  },
]

export const APPLICATION_ACCEPT = [
  {
    label: "お弁当",
    value: "lunch-box",
  },
  {
    label: "お弁当種類",
    value: "lunch-type",
  },
  {
    label: "送迎",
    value: "pick-up",
  },
  {
    label: "お迎え希望時間",
    value: "desired-pick-up",
  },
  {
    label: "送り希望時間",
    value: "desired-see-off",
  },
  {
    label: "実費(表示のみ)",
    value: "actual-cost",
  },
  {
    label: "備考欄",
    value: "remark",
  },
]

export const SERVICE_PROVISION_RECORD = [
  {
    label: "「何枚中何枚」の欄を表示する",
    value: "「何枚中何枚」の欄を表示する",
  },
  {
    label: "様式番号を表示する",
    value: "様式番号を表示する",
  },
  {
    label: "「上記内容について確認しました。」を表示する",
    value: "「上記内容について確認しました。」を表示する",
  },
  {
    label: "「上記内容について確認しました。」を表示する",
    value: "「上記内容について確認しました。」を表示する",
  },
  {
    label: "下部に日付記入欄を表示する",
    value: "下部に日付記入欄を表示する",
  },
  {
    label: "保護者等確認欄を無くし、下部に表示する",
    value: "保護者等確認欄を無くし、下部に表示する",
  },
  {
    label: "デジタルサイン署名欄に日付を記入できるようにする",
    value: "デジタルサイン署名欄に日付を記入できるようにする",
  },
  {
    label: "欠席時対応加算理由を表示する",
    value: "欠席時対応加算理由を表示する",
  },
]

export const SUPPORT_PLAN_OPTION = [
  {
    label: "説明同意日",
    value: "説明同意日",
  },
  {
    label: "保護者サイン欄",
    value: "保護者サイン欄",
  },
  {
    label: "同意説明文",
    value: "同意説明文",
  },
  {
    label: "児童サイン欄",
    value: "児童サイン欄",
  },
  {
    label: "児童発達支援管理責任者の同意説明文",
    value: "児童発達支援管理責任者の同意説明文",
  },
  {
    label: "保護者印鑑",
    value: "保護者印鑑",
  },
]

export const RECORD_SETTING = [
  {
    label: "時間",
    value: "時間",
  },
  {
    label: "体温",
    value: "体温",
  },
  {
    label: "食事",
    value: "食事",
  },
  {
    label: "おやつ",
    value: "おやつ",
  },
  {
    label: "画像",
    value: "画像",
  },
  {
    label: "今日の活動",
    value: "今日の活動",
  },
  {
    label: "サービス提供内容",
    value: "サービス提供内容",
  },
  {
    label: "個別支援計画振り返り",
    value: "個別支援計画振り返り",
  },
  {
    label: "コメント（本日の様子）",
    value: "コメント（本日の様子）",
  },
]

//childList

export const CHILD_FACILITY_OPTION = [
  {
    label: "石巻 ",
    value: "石巻 ",
  },
  {
    label: "つくば(3人)",
    value: "つくば(3人)",
  },
  {
    label: "山武(3人)",
    value: "山武(3人)",
  },
  {
    label: "東松山(3人)",
    value: "東松山(3人)",
  },
  {
    label: "(放) 宮子(3人)",
    value: "(放) 宮子(3人)",
  },
  {
    label: "(放) 小泉(3人)",
    value: "(放) 小泉(3人)",
  },
  {
    label: "本庄(3人)",
    value: "本庄(3人)",
  },
  {
    label: "吉岡(3人)",
    value: "吉岡(3人)",
  },
  {
    label: "熊谷(3人)",
    value: "熊谷(3人)",
  },
  {
    label: "太田(3人)",
    value: "太田(3人)",
  },
  {
    label: "宮子(3人)",
    value: "宮子(3人)",
  },
  {
    label: "小泉(13人)",
    value: "小泉(13人)",
  },
  {
    label: "契約施設無し(31人)",
    value: "契約施設無し(31人)",
  },
]

export const CHILD_SERVICE_OPTION = [
  {
    label: "After school day service",
    value: "1",
    colorCode: "red",
  },
  {
    label: "Child development support",
    value: "2",
    colorCode: "blue",
  },
]

//get 31 day list
export const getDaysList = () => {
  const daysList = []
  for (let i = 1; i <= 31; i++) {
    daysList.push(i)
  }
  return daysList.map((item) => {
    return {
      label: item,
      value: item,
    }
  })
}

//get all 12 month list
export const getMonthList = () => {
  const monthsList = []
  for (let i = 1; i <= 12; i++) {
    monthsList.push(i)
  }
  return monthsList.map((item) => {
    return {
      label: `${item}`,
      value: item,
    }
  })
}

//get all year from now to 1990 list
export const getYearList = () => {
  const yearNow = Number(dayjs().format("YYYY"))
  const yearList = []
  for (let i = yearNow + 5; i >= 2021; i = i - 1) {
    yearList.push(i)
  }
  return yearList.map((item) => {
    return {
      label: `${item}`,
      value: item,
    }
  })
}

//get all year from now to 1990 list
export const getPastYearList = (reverse?: boolean) => {
  const yearNow = Number(dayjs().format("YYYY"))
  const yearList = []
  for (let i = 1980; i <= yearNow; i = i + 1) {
    yearList.push(i)
  }
  const list = yearList.map((item) => {
    return {
      label: `${item}`,
      value: item,
    }
  })
  if (reverse) {
    return list.reverse()
  }
  return list
}

export const DEFAULT_FACILITY_APPLICATION_SETTINGS = {
  accept_cancel_waiting_flg: "0",
  accept_over_app: "0",
  cancel_waiting_distribution: "1",
}
// notification types
export const notificationTypes = [
  {
    label: "Important",
    value: "important",
  },
  {
    label: "Update",
    value: "update",
  },
  {
    label: "Notification",
    value: "notification",
  },
]

// account type
export const ACCOUNT_TYPE = [
  {
    label: "Normal",
    value: 1,
  },
  {
    label: "Current",
    value: 2,
  },
  {
    label: "Tax prep",
    value: 3,
  },
  {
    label: "Savings",
    value: 4,
  },
  {
    label: "Others",
    value: 5,
  },
]

export const ACCOUNT_TYPE_KEY_VAL = {
  0: "---",
  1: "Normal",
  2: "Current",
  3: "Tax prep",
  4: "Savings",
  5: "Others",
}

export const CHILDREN_CONTRACT_FACILITY = [
  {
    label: "つくば",
    value: "つくば",
  },
  {
    label: "山武",
    value: "山武",
  },
  {
    label: "東松山",
    value: "東松山",
  },
  {
    label: "(放) 宮子",
    value: "(放) 宮子",
  },
  {
    label: "(放) 小泉",
    value: "(放) 小泉",
  },
  {
    label: "本庄",
    value: "本庄",
  },
  {
    label: "吉岡",
    value: "吉岡",
  },
  {
    label: "熊谷",
    value: "熊谷",
  },
  {
    label: "太田",
    value: "太田",
  },
  {
    label: "宮子",
    value: "宮子",
  },
  {
    label: "小泉",
    value: "小泉",
  },
  {
    label: "契約施設無し",
    value: "契約施設無し",
  },
]

export const PREFECTURES = [
  {
    label: "東京都",
    value: "東京都",
  },
  {
    label: "北海道",
    value: "北海道",
  },
  {
    label: "青森県",
    value: "青森県",
  },
  {
    label: "岩手県",
    value: "岩手県",
  },
  {
    label: "宮城県",
    value: "宮城県",
  },
  {
    label: "秋田県",
    value: "秋田県",
  },
  {
    label: "山形県",
    value: "山形県",
  },
  {
    label: "福島県",
    value: "福島県",
  },
  {
    label: "茨城県",
    value: "茨城県",
  },
  {
    label: "栃木県",
    value: "栃木県",
  },
  {
    label: "群馬県",
    value: "群馬県",
  },
  {
    label: "埼玉県",
    value: "埼玉県",
  },
  {
    label: "千葉県",
    value: "千葉県",
  },
  {
    label: "神奈川県",
    value: "神奈川県",
  },
  {
    label: "新潟県",
    value: "新潟県",
  },
  {
    label: "富山県",
    value: "富山県",
  },
  {
    label: "石川県",
    value: "石川県",
  },
  {
    label: "福井県",
    value: "福井県",
  },
  {
    label: "山梨県",
    value: "山梨県",
  },
  {
    label: "長野県",
    value: "長野県",
  },
  {
    label: "岐阜県",
    value: "岐阜県",
  },
  {
    label: "静岡県",
    value: "静岡県",
  },
  {
    label: "愛知県",
    value: "愛知県",
  },
  {
    label: "三重県",
    value: "三重県",
  },
  {
    label: "滋賀県",
    value: "滋賀県",
  },
  {
    label: "京都府",
    value: "京都府",
  },
  {
    label: "大阪府",
    value: "大阪府",
  },
  {
    label: "兵庫県",
    value: "兵庫県",
  },
  {
    label: "奈良県",
    value: "奈良県",
  },
  {
    label: "和歌山県",
    value: "和歌山県",
  },
  {
    label: "鳥取県",
    value: "鳥取県",
  },
  {
    label: "島根県",
    value: "島根県",
  },
  {
    label: "岡山県",
    value: "岡山県",
  },
  {
    label: "広島県",
    value: "広島県",
  },
  {
    label: "山口県",
    value: "山口県",
  },
  {
    label: "徳島県",
    value: "徳島県",
  },
  {
    label: "香川県",
    value: "香川県",
  },
  {
    label: "愛媛県",
    value: "愛媛県",
  },
  {
    label: "高知県",
    value: "高知県",
  },
  {
    label: "福岡県",
    value: "福岡県",
  },
  {
    label: "佐賀県",
    value: "佐賀県",
  },
  {
    label: "長崎県",
    value: "長崎県",
  },
  {
    label: "熊本県",
    value: "熊本県",
  },
  {
    label: "大分県",
    value: "大分県",
  },
  {
    label: "宮崎県",
    value: "宮崎県",
  },
  {
    label: "鹿児島県",
    value: "鹿児島県",
  },
  {
    label: "沖縄県",
    value: "沖縄県",
  },
]

export const PREFECTURE_OPTIONS = PREFECTURES.map((item, index) => {
  return {
    label: item.label,
    value: index + 1,
  }
})

export const DISPLAY_CONTENT = [
  {
    label: "List of messages received from parents",
    value: "1",
  },
  {
    label: "List of message sent from the facility",
    value: "2",
  },
]

export const STATUS = (displayContent?: any) => {
  const statusList = [
    {
      label: "Unread",
      value: "0",
    },
    {
      label: "Read",
      value: "1",
    },
  ]

  if (displayContent == "1") {
    statusList.push({
      label: "No notes from parents",
      value: "2",
    })
  }
  return statusList
}

export const CHILD_SERVICE_TYPES = {
  1: "After school day service",
  2: "Child development support",
}

export const SERVICE_CODE_FOR_CHILD_SUPPORT_DISABILITY = {
  "1": "611000",
  "2": "613000",
  "3": "612000",
  "4": "631000",
  "5": "632000",
}

export const benefitPaymentAmount = () => {
  const amounts = Array.from({ length: 32 }, (_, i) => ({
    label: `${i}`,
    value: `${i}`,
  }))
  return [
    {
      label: "No of days in a month",
      value: "-",
    },
    ...amounts,
  ]
}

export const benefitPaymentAmountNegative = () => {
  const amounts = Array.from({ length: 31 }, (_, i) => ({
    label: i === 0 ? `${i}` : `-${i}`,
    value: i === 0 ? `${i}` : `-${i}`,
  }))
  return amounts
}

export const BURDERN_UPPER_LIMIT = [
  { label: "0", value: 0 },
  { label: "4,600", value: 4600 },
  { label: "18,600", value: 18600 },
  { label: "37,200", value: 37200 },
]
export const CHILD_GUIDENCE = [
  {
    label: "Regular class",
    value: "Regular class",
  },
  {
    label: "Class instruction classroom",
    value: "Class instruction classroom",
  },
  {
    label: "special needs class",
    value: "special needs class",
  },
  {
    label: "Special support school",
    value: "Special support school",
  },
]

export const SERVICE_OPTION = [
  {
    label: CHILD_SERVICE_TYPES?.[1],
    value: "1",
  },
  {
    label: CHILD_SERVICE_TYPES?.[2],
    value: "2",
  },
]

export const COMMENT_STATUS = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Read",
    value: "1",
  },
  {
    label: "Set to read",
    value: "0",
  },
]

export const MEDICAL_CARE_CHILD_TYPE = [
  { label: "Division 1", value: 1 },
  { label: "Division 2", value: 2 },
]

export const MULTIPLE_CHILD_REDUCTION_TYPE = [
  { label: "2nd child", value: 1 },
  { label: "3rd or above", value: 2 },
]

export const UPPER_LIMIT_CONTROL_ADDITION_ACQUISATIONS = [
  { label: "Do not acquire", value: 1 },
  { label: "Acquire", value: 2 },
]

export const getFacilityOptionsBasedOnMangementCompany = (
  company,
  ownFacility,
  otherFacility,
) => {
  if (company === "company" || company == 1) {
    return ownFacility?.map(({ facility_name_short, id }) => ({
      label: facility_name_short,
      value: id,
    }))
  }
  if (company === "other" || company == 2) {
    return otherFacility?.map(({ label, value }) => {
      return {
        label,
        value,
      }
    })
  }
}

export const childDisorderTypeOptions = [
  {
    label: "children with severe behavioral disorders",
    value: "1",
  },
  {
    label: "Individual support addition (I) applicable",
    value: "2",
  },
  {
    label: "Individual support addition (II) applicable",
    value: "3",
  },
]

export const upperLimitManagementCompanyOptions = [
  {
    label: "None",
    value: 0,
  },
  {
    label: "Own facility",
    value: 1,
  },
  {
    label: "Other facilities",
    value: 2,
  },
]

export const serviceType = {
  1: "放課後等デイサービス",
  2: "児童発達支援",
}

export const upperLimitManagementCompanyType = {
  0: "None",
  1: "Own facility",
  2: "Other facilities",
}
export const TIME_INTERVALS = [
  {
    label: "06:00",
    value: "06:00",
  },
  {
    label: "06:15",
    value: "06:15",
  },
  {
    label: "06:30",
    value: "06:30",
  },
  {
    label: "06:45",
    value: "06:45",
  },
  {
    label: "07:00",
    value: "07:00",
  },
  {
    label: "07:15",
    value: "07:15",
  },
  {
    label: "07:30",
    value: "07:30",
  },
  {
    label: "07:45",
    value: "07:45",
  },
  {
    label: "08:00",
    value: "08:00",
  },
  {
    label: "08:15",
    value: "08:15",
  },
  {
    label: "08:30",
    value: "08:30",
  },
  {
    label: "08:45",
    value: "08:45",
  },
  {
    label: "09:00",
    value: "09:00",
  },
  {
    label: "09:15",
    value: "09:15",
  },
  {
    label: "09:30",
    value: "09:30",
  },
  {
    label: "09:45",
    value: "09:45",
  },
  {
    label: "10:00",
    value: "10:00",
  },
  {
    label: "10:15",
    value: "10:15",
  },
  {
    label: "10:30",
    value: "10:30",
  },
  {
    label: "10:45",
    value: "10:45",
  },
  {
    label: "11:00",
    value: "11:00",
  },
  {
    label: "11:15",
    value: "11:15",
  },
  {
    label: "11:30",
    value: "11:30",
  },
  {
    label: "11:45",
    value: "11:45",
  },
  {
    label: "12:00",
    value: "12:00",
  },
  {
    label: "12:15",
    value: "12:15",
  },
  {
    label: "12:30",
    value: "12:30",
  },
  {
    label: "12:45",
    value: "12:45",
  },
  {
    label: "13:00",
    value: "13:00",
  },
  {
    label: "13:15",
    value: "13:15",
  },
  {
    label: "13:30",
    value: "13:30",
  },
  {
    label: "13:45",
    value: "13:45",
  },
  {
    label: "14:00",
    value: "14:00",
  },
  {
    label: "14:15",
    value: "14:15",
  },
  {
    label: "14:30",
    value: "14:30",
  },
  {
    label: "14:45",
    value: "14:45",
  },
  {
    label: "15:00",
    value: "15:00",
  },
  {
    label: "15:15",
    value: "15:15",
  },
  {
    label: "15:30",
    value: "15:30",
  },
  {
    label: "15:45",
    value: "15:45",
  },
  {
    label: "16:00",
    value: "16:00",
  },
  {
    label: "16:15",
    value: "16:15",
  },
  {
    label: "16:30",
    value: "16:30",
  },
  {
    label: "16:45",
    value: "16:45",
  },
  {
    label: "17:00",
    value: "17:00",
  },
  {
    label: "17:15",
    value: "17:15",
  },
  {
    label: "17:30",
    value: "17:30",
  },
  {
    label: "17:45",
    value: "17:45",
  },
  {
    label: "18:00",
    value: "18:00",
  },
  {
    label: "18:15",
    value: "18:15",
  },
  {
    label: "18:30",
    value: "18:30",
  },
  {
    label: "18:45",
    value: "18:45",
  },
  {
    label: "19:00",
    value: "19:00",
  },
  {
    label: "19:15",
    value: "19:15",
  },
  {
    label: "19:30",
    value: "19:30",
  },
  {
    label: "19:45",
    value: "19:45",
  },
  {
    label: "20:00",
    value: "20:00",
  },
]

export const SERVICES = [
  { label: "After day school service", value: "1" },
  { label: "Child development service", value: "2" },
]

export const CHILD_SERVICES_OBJECT = {
  "1": "After day school service",
  "2": "Child development service",
}

export const REMARK_OPTION = [
  {
    label: "Facility application remarks",
    value: "1",
  },
  {
    label: "Actual expenses/expenses",
    value: "2",
  },
  {
    label: "Addition item",
    value: "3",
  },
]

export const UPDATE_OR_NOT_LIST = [
  {
    label: "Do not update",
    value: 0,
  },
  { label: "Want to update", value: 1 },
]

export const NO_YES_LIST = [
  { label: "No", value: 0 },
  {
    label: "Yes",
    value: 1,
  },
]

export const SCHEDULE_DAYS = [
  {
    label: "Sun",
    value: "0",
  },
  {
    label: "Mon",
    value: "1",
  },
  {
    label: "Tue",
    value: "2",
  },
  {
    label: "Wed",
    value: "3",
  },
  {
    label: "Thu",
    value: "4",
  },
  {
    label: "Fri",
    value: "5",
  },
  {
    label: "Sat",
    value: "6",
  },
]

export const PRINT_SETTINGS_VALUES = {
  DISPLAY_HOW_MANY_COLUMN_VALUE: "1",
  DISPLAY_FORM_NUMBER_VALUE: "2",
  CHANGE_DISABLE_CHILD_NAME_VALUE: "3",
  CONFIRMED_CONTENT_VALUE: "4",
  SHOW_DATE_FIELD_BOTTOM_VALUE: "5",
  ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE: "6",
  DISPLAY_ACHIEVEMENT_IN_BLANK_PAGE_VALUE: "7",
  DISPLAY_PLANNED_USE_DATE_VALUE: "8",
  REFLECT_DESIRED_PICK_DROP_TIMES_IN_START_END_VALUE: "9",
  LEAVE_FIELD_FOR_GUARDIAN_CONFIRMATION_VALUE: "14",
  DISPLAY_CHILD_NAME_AT_BOTTOM_VALUE: "11",
  DISPLAY_PARENT_SIGNATURE_FIELD_AT_BOTTOM_VALUE: "12",
  DISPLAY_SEAL_COLUMN_AT_BOTTOM_VALUE: "13",
  DISPLAY_DIGITAL_SIGNATURE: "10",
}

export const PRINT_SETTINGS = [
  {
    label: "Display the 'how many out of how many' column",
    value: PRINT_SETTINGS_VALUES.DISPLAY_HOW_MANY_COLUMN_VALUE,
  },
  {
    label: "Display the form number",
    value: PRINT_SETTINGS_VALUES.DISPLAY_FORM_NUMBER_VALUE,
  },
  {
    label: "Change 'Disabled user's name' to 'User's's name'",
    value: PRINT_SETTINGS_VALUES.CHANGE_DISABLE_CHILD_NAME_VALUE,
  },
  {
    label: "Display 'I have confirmed the above contents.'",
    value: PRINT_SETTINGS_VALUES.CONFIRMED_CONTENT_VALUE,
  },
  {
    label: "Show date field at bottom",
    value: PRINT_SETTINGS_VALUES.SHOW_DATE_FIELD_BOTTOM_VALUE,
  },

  {
    label:
      "Eliminate the User confirmation column and display it at the bottom",
    value: PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE,
  },
  {
    label: "Display achievements on a blank page",
    value: PRINT_SETTINGS_VALUES.DISPLAY_ACHIEVEMENT_IN_BLANK_PAGE_VALUE,
  },
  {
    label: "Display planned use date",
    value: PRINT_SETTINGS_VALUES.DISPLAY_PLANNED_USE_DATE_VALUE,
  },
]

export const ADDITIONAL_FIELDS_WHEN_ELIMINATE_PARENTAL_CONFIRMATION_FIELD_IS_CHECKED =
  [
    {
      label: "Leave the field for confirmation of user, etc.",
      value: PRINT_SETTINGS_VALUES.LEAVE_FIELD_FOR_GUARDIAN_CONFIRMATION_VALUE,
    },

    {
      label: "Display signature field for user at the bottom",
      value:
        PRINT_SETTINGS_VALUES.DISPLAY_PARENT_SIGNATURE_FIELD_AT_BOTTOM_VALUE,
    },
    {
      label: "Display the seal column at the bottom",
      value: PRINT_SETTINGS_VALUES.DISPLAY_SEAL_COLUMN_AT_BOTTOM_VALUE,
    },
  ]

export const PRINT_SETTINGS_WHEN_SCHEDULED_DAYS_IS_CHECKED = [
  {
    label:
      "Reflect the desired pick-up and drop-off times in the start and end times",
    value:
      PRINT_SETTINGS_VALUES.REFLECT_DESIRED_PICK_DROP_TIMES_IN_START_END_VALUE,
  },
]

export const REMARK_CONTENT_VALUES = {
  REMARKS: "1",
  REMARKS_OUTPUT_NHIF: "2",
  ACTUAL_EXPENSES: "3",
  REASON_EXTRA_CHARGE_ABSENTEEISM: "4",
}

export const REMARK_CONTENT = [
  {
    label: "Remarks",
    value: REMARK_CONTENT_VALUES.REMARKS,
  },
  {
    label:
      "Remarks output when making a request to the National Health Insurance Federation",
    value: REMARK_CONTENT_VALUES.REMARKS_OUTPUT_NHIF,
  },
  {
    label: "Actual expenses/expenses",
    value: REMARK_CONTENT_VALUES.ACTUAL_EXPENSES,
  },
  {
    label: "Reasons for extra charge for absenteeism",
    value: REMARK_CONTENT_VALUES.REASON_EXTRA_CHARGE_ABSENTEEISM,
  },
]

export const SERVICE_PROVISION_REMARK_CONTENT = [
  {
    label: "Remarks",
    value: REMARK_CONTENT_VALUES.REMARKS,
  },
  {
    label:
      "Remarks output when making a request to the National Health Insurance Federation",
    value: REMARK_CONTENT_VALUES.REMARKS_OUTPUT_NHIF,
  },
]

export const ACTUAL_EXPENSES_RADIO_OPTION_VALUES = {
  DONOT_INCLUDE_ACTUAL_COST_OF_0_YEN: "1",
  INCLUDE_ACTUAL_COST_OF_0_YEN: "2",
}

export const ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED = [
  {
    label: "Do not include actual cost of 0 yen",
    value:
      ACTUAL_EXPENSES_RADIO_OPTION_VALUES.DONOT_INCLUDE_ACTUAL_COST_OF_0_YEN,
  },
  {
    label: "Includes actual cost of 0 yen",
    value: ACTUAL_EXPENSES_RADIO_OPTION_VALUES.INCLUDE_ACTUAL_COST_OF_0_YEN,
  },
]

export const REASON_FOR_EXTRA_CHARGE_RADIO_OPTION_VALUES = {
  DO_NOT_DISPLAY_REASON_FOR_NO_ADDITION: "1",
  DISPLAY_REASON_FOR_EXTRA_CHARGE_FOR_ABSENTEEISM: "2",
}

export const ADDITIONAL_REMARK_CONTENT_WHEN_REASON_FOR_EXTRA_CHARGE_IS_CHECKED =
  [
    {
      label: "The reason for no addition is not displayed.",
      value:
        REASON_FOR_EXTRA_CHARGE_RADIO_OPTION_VALUES.DO_NOT_DISPLAY_REASON_FOR_NO_ADDITION,
    },
    {
      label: "Reasons for extra charge for absenteeism",
      value:
        REASON_FOR_EXTRA_CHARGE_RADIO_OPTION_VALUES.DISPLAY_REASON_FOR_EXTRA_CHARGE_FOR_ABSENTEEISM,
    },
  ]

export const ADDITIONAL_ITEM = [
  {
    label: "Visit special support addition",
    value: ADDITIONAL_ITEM_VALUES.VISIT_SPECIAL_SUPPORT,
  },
  {
    label: "Trial use support addition",
    value: ADDITIONAL_ITEM_VALUES.TRIAL_USE_SUPPORT,
  },
  {
    label: "Absence addition",
    value: ADDITIONAL_ITEM_VALUES.ABSENCE_ADDITION,
  },
  {
    label: "Social life support special addition",
    value: ADDITIONAL_ITEM_VALUES.SOCIAL_LIFE_SUPPORT_SPECIAL,
  },
  {
    label: "Support for living at home addition",
    value: ADDITIONAL_ITEM_VALUES.SUPPORT_FOR_LIVING_AT_HOME,
  },
  {
    label: "Medical cooperation system addition",
    value: ADDITIONAL_ITEM_VALUES.MEDICAL_COOPERATION_SYSTEM,
  },
  {
    label: "Employment transition cooperation addition",
    value: ADDITIONAL_ITEM_VALUES.EMPLOYMENT_TRANSITION_COOPERATION,
  },
  {
    label: "User burden limit management addition calculation",
    value: ADDITIONAL_ITEM_VALUES.USER_BURDEN_LIMIT_MANAGEMENT,
  },
]

export const DIGITALSIGN_VALUES = {
  SIGN_AND_DISPLAY_WITH_SIGNATURE_VALUE: "1",
  ENTER_AND_DISPLAY_DATE_IN_SIGNATURE_LINE: "2",
}

export const DIGITALSIGN_OPTION = [
  {
    label: "Sign and display with a digital signature",
    value: DIGITALSIGN_VALUES.SIGN_AND_DISPLAY_WITH_SIGNATURE_VALUE,
  },
]

export const DIGITALSIGN_SUB_OPTION = [
  {
    label: "Enter and display the date in the signature line",
    value: DIGITALSIGN_VALUES.ENTER_AND_DISPLAY_DATE_IN_SIGNATURE_LINE,
  },
]

export const INITIAL_DIGITALSIGN_OPTION = [
  {
    label: "Sign and display with a digital signature",
    value: "1",
  },
]

export const ABSENCE_REASON_DISPLAY_VALUES = {
  NOT_DISPLAYED: "1",
  DISPLAYED: "2",
}

export const ADDITIONAL_FIELDS_WHEN_ABSENT_NO_ADDITION_AND_REASONS_ARE_CHECKED =
  [
    {
      label: "The reason for no addition is not displayed.",
      value: ABSENCE_REASON_DISPLAY_VALUES.NOT_DISPLAYED,
    },
    {
      label: "Also display the reason for no addition",
      value: ABSENCE_REASON_DISPLAY_VALUES.DISPLAYED,
    },
  ]

export const PICKUP_DISPLAY_OPTIONS = [
  {
    key: "location",
    label: "Pickup location",
    value: 1,
  },
  {
    key: "circle",
    label: "Display with ◯",
    value: 2,
  },
]

export const ACCOUNT_TYPE_OPTIONS = [
  {
    label: "---",
    value: 0,
  },
  {
    label: "Current",
    value: 1,
  },
  {
    label: "Normal",
    value: 2,
  },
  {
    label: "Savings",
    value: 3,
  },
  {
    label: "Others",
    value: 4,
  },
]

export const ADDITION_TYPE_OPTIONS = [
  // Commented for now as per the discussion , may need to use in future
  /*   { label: "Family collaboration addition", value: 10 },
  { label: "In-office consultation support addition (I)", value: 30 },
  { label: "In-office consultation support addition (II)", value: 40 },
  { label: "Related institution collaboration addition (I)", value: 80 },
  { label: "Related period collaboration addition (II)", value: 90 },
  { label: "Special support addition", value: 50 },
  { label: "Severe behavioral disorder support addition", value: 110 },
  {
    label: "Transition support addition for childcare/education, etc.",
    value: 120,
  }, */
  {
    label: "Home cooperation addition",
    value: 130,
  },
  {
    label: "Addition of in-house consultation support (1)",
    value: 140,
  },
  {
    label: "Addition of in-house consultation support (II)",
    value: 150,
  },
  {
    label: "Related organizations (1)",
    value: 160,
  },
  {
    label: "Related Organizations (II)",
    value: 170,
  },
  {
    label: "Special Support bonus",
    value: 180,
  },
  {
    label: "Strength Behavioral disorder child support addition",
    value: 190,
  },
  {
    label: "Addition of childcare/education transition support",
    value: 200,
  },
  { label: "Other records", value: 999 },
]

export const ADDITIONAL_NAME_VALUES = {
  VISIT_1_HOUR_MORE: 1,
  VISIT_1_HOUR_LESS: 2,
  OTHER: 3,
}

export const ADDITION_NAME_TITLE_OPTIONS = [
  {
    label: "Visiting support special addition (1 hour or more)",
    value: ADDITIONAL_NAME_VALUES.VISIT_1_HOUR_MORE,
  },
  {
    label: "Visiting support special addition (less than 1 hour)",
    value: ADDITIONAL_NAME_VALUES.VISIT_1_HOUR_LESS,
  },
  {
    label: "Other record",
    value: ADDITIONAL_NAME_VALUES.OTHER,
  },
]

export const ADDITION_NAME_PRINT_TITLE_OPTIONS = [
  {
    label: "Visiting support(1 hour or more)",
    value: ADDITIONAL_NAME_VALUES.VISIT_1_HOUR_MORE,
  },
  {
    label: "Visiting support(Less than 1 hour)",
    value: ADDITIONAL_NAME_VALUES.VISIT_1_HOUR_LESS,
  },
  {
    label: "Other record",
    value: ADDITIONAL_NAME_VALUES.OTHER,
  },
]

export const ADDITION_TYPE_OPTIONS_OBJ = {
  10: "Family collaboration addition",
  30: "In-office consultation support addition (I)",
  40: "In-office consultation support addition (II)",
  80: "Related institution collaboration addition (I)",
  90: "Related period collaboration addition (II)",
  50: "Special support addition",
  110: "Severe behavioral disorder support addition",
  120: "Transition support addition for childcare/education, etc.",
  130: "Home cooperation addition",
  140: "Addition of in-house consultation support (1)",
  150: "Addition of in-house consultation support (II)",
  160: "Related organizations (1)",
  170: "Related Organizations (II)",
  180: "Special Support bonus",
  190: "Strength Behavioral disorder child support addition",
  200: "Addition of childcare/education transition support",
  999: "Other records",
}

export const ALL_FACILITY_KINDS = [
  {
    label: "After school day service",
    value: 1,
  },

  {
    label: "Child Development Support",
    value: 2,
  },
]

export const CAR_COLOR = [
  {
    label: "Brown",
    value: 1,
  },
  {
    label: "Red",
    value: 2,
  },
  {
    label: "Reddish purple",
    value: 3,
  },
  {
    label: "Purple",
    value: 4,
  },
  {
    label: "Blue",
    value: 5,
  },
  {
    label: "Yellow-green",
    value: 6,
  },
  {
    label: "Green",
    value: 7,
  },
  {
    label: "White",
    value: 8,
  },
  {
    label: "Silver",
    value: 9,
  },
  {
    label: "Yellow",
    value: 10,
  },
]
export const LOCATIONS_VALUES = {
  HOME: 1,
  OTHER: 2,
}

export const LOCATIONS = [
  {
    label: "--",
    value: "",
  },
  {
    label: "one's home",
    value: LOCATIONS_VALUES.HOME,
  },
  {
    label: "others",
    value: LOCATIONS_VALUES.OTHER,
  },
]

export const LOCATIONS_FOR_TRANSPORT_BULK_EDIT = [
  {
    label: "Nursery school",
    value: 1,
  },
  {
    label: "one's home",
    value: 2,
  },
  {
    label: "others",
    value: 3,
  },
]

export const SUBSCRIBE_UNSUBSCRIBE_FOR_INFORMATION_COLLECTION = [
  {
    label: "Make output",
    value: 1,
  },
  {
    label: "No output",
    value: 2,
  },
]
export const CHILDINFORMATION = [
  {
    label: "Child name",
    value: "ChildName",
  },
  {
    label: "Child furigana",
    value: "ChildNameFurigana",
  },
  {
    label: "DOB",
    value: "DOB",
  },
  {
    label: "Gender",
    value: "Gender",
  },
  {
    label: "Hospital name",
    value: "HospitalName",
  },
  {
    label: "Doctor name",
    value: "DoctorName",
  },
  {
    label: "Hospital contact information",
    value: "HospitalContact",
  },
  {
    label: "Day nursery name",
    value: "DayNurseryName",
  },
  {
    label: "Day nursery contact information",
    value: "DayNurseryContact",
  },
  {
    label: "School year",
    value: "SchoolYear",
  },
  {
    label: "Guidance",
    value: "Guidance",
  },
  {
    label: "Teacher",
    value: "Teacher",
  },
  {
    label: "Allergy",
    value: "Allergy",
  },
  {
    label: "Symptoms",
    value: "Symptoms",
  },
  {
    label: "Family structure",
    value: "FamilyStructure",
  },
  {
    label: "Remarks",
    value: "Remarks",
  },
  {
    label: "Special skill· thing of interest",
    value: "ThingOfInterest",
  },
  {
    label: "Things to watch out for",
    value: "ThingsToWatchOut",
  },
]
export const OUTPUT_FORMAT_FOR_INFORMATION_COLLECTION = [
  {
    label: "Separate sheets for each facility",
    value: 1,
  },
  {
    label: "Output all information in one sheet",
    value: 2,
  },
]
export const PARENTSINFORMATION = [
  {
    label: "Parents name",
    value: "ParentName",
  },
  {
    label: "Parents name furigana",
    value: "ParentNameFurigana",
  },
  {
    label: "Po box",
    value: "PoBox",
  },
  {
    label: "Address (prefecture)",
    value: "AddressPrefecture",
  },
  {
    label: "Address",
    value: "Address",
  },
  {
    label: "Phone number",
    value: "ParentPhoneNumber",
  },
  {
    label: "Relation with child",
    value: "RelationWithChild",
  },
  {
    label: "Day nursery contact",
    value: "ParentDayNurseryContact",
  },
  {
    label: "Remarks",
    value: "ParentRemarks",
  },
  {
    label: "Emergency phone number 1",
    value: "EmergencyPhone1",
  },
  {
    label: "Emergency contact 1",
    value: "EmergencyContact1",
  },
  {
    label: "Emergency phone no. 2",
    value: "EmergencyPhone2",
  },
  {
    label: "Emergency contact. 2",
    value: "EmergencyContact2",
  },
  {
    label: "Emergency contact remarks",
    value: "EmergencyContactRemarks",
  },
  {
    label: "Financial institution name Kana",
    value: "FinancialInstituteNameKana",
  },
  {
    label: "Branch name Kana",
    value: "BranchNameKana",
  },
  {
    label: "Financial institution number",
    value: "FinancialInstituteNumber",
  },
  {
    label: "Branch code",
    value: "BranchCode",
  },
  {
    label: "Deposit item",
    value: "DepositItem",
  },
  {
    label: "Account number",
    value: "AccountNumber",
  },
  {
    label: "Account holder",
    value: "AccountHolderName",
  },
  {
    label: "Customer code",
    value: "CustomerCode",
  },
  {
    label: "Email",
    value: "Email",
  },
  {
    label: "Email address (If multiple)",
    value: "Email2",
  },
  {
    label: "Email notification setting",
    value: "EmailNotificationSetting",
  },
]
export const RECIPIENTINFORMATION = [
  {
    label: "Beneficiary certificate usage start date",
    value: "CertificateStartDate",
  },
  {
    label: "Beneficiary certificate number",
    value: "CertificateNumber",
  },
  {
    label: "Provision city",
    value: "PaymentMunicipality",
  },
  {
    label: "use service",
    value: "ServiceUsed",
  },
  {
    label: "Children with disabilities",
    value: "ChildrenWithDisability",
  },
  {
    label: "Medical Decision Score",
    value: "MedicalJudgementScore",
  },
  {
    label: "Division of children with medical care",
    value: "DivisionOfChildrenWithMedicalCare",
  },
  {
    label: "Special support addition",
    value: "SpecialAreaAddition",
  },
  {
    label: "Benefit determination period start date",
    value: "BenefitDeterminationStartDate",
  },
  {
    label: "Benefit determination period end date",
    value: "BenefitDeterminationEndDate",
  },
  {
    label: "Provision period start date",
    value: "PaymentStartDate",
  },
  {
    label: "Provision period end date",
    value: "PaymentEndDate",
  },
  {
    label: "Consultation support office",
    value: "ConsultationSupportOffice",
  },
  {
    label: "Consultation support specialist",
    value: "ConsultationSupportSpecialist",
  },
  {
    label: "Burden upper limit monthly amount",
    value: "BurdenUpperLimitMonthlyAmount",
  },
  {
    label: "Applicable period start date",
    value: "ApplicationStartDate",
  },
  {
    label: "Applicable period end date",
    value: "ApplicationEndDate",
  },
  {
    label: "Upper limit management company",
    value: "UpperLimitManagementCompany",
  },
  {
    label: "Upper limit control addition acquisition setting",
    value: "UpperLimitAcquisitionSetting",
  },
  {
    label: "Multi-child reduction target category",
    value: "MultiChildReductionTargetCategory",
  },
  {
    label: "Children eligible for free of charge start date",
    value: "EligibleChildStartDate",
  },
  {
    label: "Children eligible for free of charge end date",
    value: "EligibleChildEndDate",
  },
]

export const FACILITYUSEINFORMATION = [
  {
    label: "Facility name",
    value: "NameOfFacility",
  },
  {
    label: "Use service Year month",
    value: "ServiceStartDate",
  },
  {
    label: "Contract start day",
    value: "ContractStartDate",
  },
  {
    label: "Service provision start day",
    value: "ServiceProvisionStartDate",
  },
  {
    label: "Benefit payment amount",
    value: "BenefitPaymentAmount",
  },
  {
    label: "Category for children requiring medical care",
    value: "DivisionOfChildrenWithMedicalCare",
  },
  {
    label: "Contract entry number",
    value: "ContractEntryNumber",
  },
]
export const JOINTFACILITYUSEINFORMATION = [
  {
    label: "Joint facility name",
    value: "facility_other_name",
  },
  {
    label: "Hiragana",
    value: "facility_name_furigana",
  },
  {
    label: "Service provision",
    value: "service_type01",
  },
  {
    label: "Address",
    value: "address",
  },
  {
    label: "Phone number",
    value: "tel_no",
  },
  {
    label: "FAX number",
    value: "fax_no",
  },
  {
    label: "Remarks",
    value: "remark_text",
  },
  {
    label: "Contract amount",
    value: "payment_amount",
  },
]
export const BODY_TEMPERATURE_OPTIONS = (start: number, end: number) => {
  const result = []
  result.push({ label: "--", value: "" })
  for (let i = start; i < end; i++) {
    if (i < 10) {
      result.push({
        label: `0${i}`,
        value: `0${i}`,
      })
    } else {
      result.push({
        label: `${i}`,
        value: `${i}`,
      })
    }
  }
  return result
}

export const BODY_TEMPERATURE_OPTIONS_ONE_TEN = (
  start: number,
  end: number,
) => {
  const result = []
  result.push({ label: "--", value: "" })
  for (let i = start; i < end; i++) {
    if (i < 10) {
      result.push({
        label: `${i}`,
        value: `${i}`,
      })
    } else {
      result.push({
        label: `${i}`,
        value: `${i}`,
      })
    }
  }
  return result
}

export const afterSchoolServicesOpt = [
  {
    label: "After school",
    value: 1,
  },
  {
    label: "School holiday",
    value: 2,
  },
]

export const defaultAbsentNote = `【欠席の連絡のあった日】

【誰が電話してきたか】

【連絡を受けた対応者】

【対応の内容】

【欠席の理由】

【当日のご本人の様子】

【次回通所予定日】
`

// values aren't certain for type II
export const AbsentTypes = [
  {
    label: "Addition for Absence(Ⅰ)",
    value: 1,
  },
  {
    label: "Addition for Absence(Ⅰ)【No addition】",
    value: 2,
  },
  {
    label: "Addition for Absence II",
    value: 3,
  },
  {
    label: "Addition for Absence II【No addition】",
    value: 4,
  },
]
export const AbsentTypesWithLineBreak = [
  {
    label: "Addition for Absence(Ⅰ).",
    value: 1,
  },
  {
    label: "Addition for Absence(Ⅰ)【No addition】.",
    value: 2,
  },
  {
    label: "Addition for Absence II",
    value: 3,
  },
  {
    label: "Addition for Absence II【No addition】.",
    value: 4,
  },
]

export const ACTUAL_EXPENSE_OPTIONS = [
  {
    label: "Does not include the actual cost of 0 yen",
    value: 1,
  },
  {
    label: "Include actual cost of 0 yen",
    value: 2,
  },
]

export const CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_1 = [
  {
    label: "When conducted at the Child Development Support Center",
    value: 1,
  },
  {
    label: "When performing outside the Child Development Support Center",
    value: 2,
  },
  {
    label: "Symbiotic Child Development Support Benefits",
    value: 3,
  },
  {
    label: "Criteria applicable child development support benefits",
    value: 4,
  },
]
export const CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_2 = [
  {
    label: "Criteria applicable child development support benefits I",
    value: 1,
  },
  {
    label: "Criteria applicable child development support benefits II",
    value: 2,
  },
]

export const AFTER_DAY_SCHOOL_REVENUE_CONDITION_1 = [
  {
    label: "Disable child type",
    value: 1,
  },
  {
    label: "Symbiosis-type after-school day care service benefit expenses",
    value: 2,
  },
]
export const UPPER_LIMIT_MANAGEMENT_COMPANY_OPTIONS = [
  {
    label: "None",
    value: 0,
  },
  {
    label: "Own facility",
    value: 1,
  },
  {
    label: "Other facilities",
    value: 2,
  },
]

export const INITIAL_PRINT_SETTINGS_ENHANCED = [
  {
    label: "Display the 'how many out of how many' column",
    value: "1",
  },
  {
    label: "Display the form number",
    value: "2",
  },
  {
    label: "Display except used day",
    value: "9",
  },
  {
    label: "Change 'Disabled child's name' to 'Child's name'",
    value: "3",
  },
  {
    label: "Display 'I have confirmed the above contents.'",
    value: "4",
  },
  {
    label: "Show date field at bottom",
    value: "5",
  },
  {
    label:
      "Eliminate the parent/guardian confirmation column and display it at the bottom",
    value: "6",
  },

  {
    label: "Display achievements on a blank page",
    value: "7",
  },

  {
    label: "Display planned use date",
    value: "8",
  },
]

export const PRINT_SETTINGS_ENHANCED = [
  {
    label: "Display the 'how many out of how many' column",
    value: "1",
  },
  {
    label: "Display the form number",
    value: "2",
  },
  {
    label: "Display except used day",
    value: "9",
  },
  {
    label: "Change 'Disabled child's name' to 'Child's name'",
    value: "3",
  },
  {
    label: "Display 'I have confirmed the above contents.'",
    value: "4",
  },
  {
    label: "Show date field at bottom",
    value: "5",
  },

  {
    label:
      "Eliminate the parent/guardian confirmation column and display it at the bottom",
    value: "6",
  },
  {
    label: "Leave the parent/guardian confirmation field",
    value: "10",
  },
  {
    label: "Display child's name at the bottom",
    value: "11",
  },
  {
    label: "Show parental signature line at bottom",
    value: "12",
  },

  {
    label: "Display the seal column at the bottom",
    value: "13",
  },
  {
    label: "Display achievements on a blank page",
    value: "7",
  },

  {
    label: "Display planned use date",
    value: "8",
  },
]

export const REMARK_CONTENT_ENHANCEMENT_VALUES = {
  REMARKS: "1",
  REMARKS_OUTPUT_NHIF: "2",
  ACTUAL_EXPENSES: "3",
  REASON_FOR_EXTRA_CHARGE_FOR_ABSENT: "4",
}

export const REMARK_CONTENT_ENHANCEMENT = [
  {
    label: "Remarks",
    value: REMARK_CONTENT_ENHANCEMENT_VALUES.REMARKS,
  },
  {
    label:
      "Remarks output when making a request to the National Health Insurance Federation",
    value: REMARK_CONTENT_ENHANCEMENT_VALUES.REMARKS_OUTPUT_NHIF,
  },
  {
    label: "Actual expenses/expenses",
    value: REMARK_CONTENT_ENHANCEMENT_VALUES.ACTUAL_EXPENSES,
  },

  {
    label: "Reasons for extra charge for absenteeism",
    value: REMARK_CONTENT_ENHANCEMENT_VALUES.REASON_FOR_EXTRA_CHARGE_FOR_ABSENT,
  },
]

export const ADDITIONAL_ITEM_ENHACED = [
  {
    label: "Extension support addition",
    value: "1",
  },
  {
    label: "Special support addition",
    value: "2",
  },
  {
    label: "Individual support addition (I)",
    value: "3",
  },
  {
    label: "Individual support addition (II)",
    value: "4",
  },

  {
    label: "Affiliated Institutions Collaboration Addition (I)",
    value: "5",
  },

  {
    label: "Affiliated institution cooperation addition (II)",
    value: "6",
  },
  {
    label: "Severe Behavioral Disorder Child Support Addition",
    value: "7",
  },
]
export const DISABLE_CHILD_OUT_PRINT_SETTIGNS = [
  {
    label: "Print stock number",
    value: "1",
  },
  {
    label: "Print users with a billing amount of 0 yen",
    value: "2",
  },
  {
    label: 'Print the "how many out of how many" column on the statement',
    value: "3",
  },
]

export const DONT_DO_LIST = [
  { label: "Do", value: "1" },
  {
    label: "Do not",
    value: "0",
  },
]

export const NO_YES_STRING_LIST = [
  {
    label: "no ",
    value: "0",
  },
  { label: "yes ", value: "1" },
]

export const CONSULT_TYPE = [
  {
    label: "Office consultation support addition (I)",
    value: 1,
  },
  {
    label: "Office consultation support addition (II)",
    value: 2,
  },
]

export const MEDICAL_SYSTEM = [
  {
    label: "Do not take additions",
    value: 0,
  },
  {
    label: "Medical system link addition I",
    value: 1,
  },
  {
    label: "Medical system link addition II",
    value: 2,
  },
  {
    label: "Medical system link addition III",
    value: 3,
  },
  {
    label: "Medical system link addition VII",
    value: 4,
  },
]

export const MEDICAL_STATUS = [
  {
    label: "1 user",
    value: 1,
  },
  {
    label: "2 user",
    value: 2,
  },
  {
    label: "3 to 8 user",
    value: 3,
  },
]

export const SP_DISPLAY_SETTINGS = [
  {
    label: "User who do not have enough daily digital signatures",
    value: 1,
  },
  {
    label: "No digital signature at the bottom of the record sheet",
    value: 2,
  },
]

export const TRANSITION_TYPE_ADDITIONAL_ITEM = [
  {
    label: "Visit special support addition",
    value: ADDITIONAL_ITEM_VALUES.VISIT_SPECIAL_SUPPORT,
  },
  {
    label: "Social life support special addition",
    value: ADDITIONAL_ITEM_VALUES.SOCIAL_LIFE_SUPPORT_SPECIAL,
  },
  {
    label: "Support for living at home addition",
    value: ADDITIONAL_ITEM_VALUES.SUPPORT_FOR_LIVING_AT_HOME,
  },
  {
    label: "Mentally disabled discharge support facility addition",
    value: ADDITIONAL_ITEM_VALUES.MENTALLY_DISABLED_DISCHARGE_SUPPORT,
  },
]

export const AB_TYPE_ADDITIONAL_ITEM = [
  {
    label: "Visit special support addition",
    value: ADDITIONAL_ITEM_VALUES.VISIT_SPECIAL_SUPPORT,
  },
  {
    label: "Social life support special addition",
    value: ADDITIONAL_ITEM_VALUES.SOCIAL_LIFE_SUPPORT_SPECIAL,
  },
  {
    label: "Support for living at home addition",
    value: ADDITIONAL_ITEM_VALUES.SUPPORT_FOR_LIVING_AT_HOME,
  },
  {
    label: "Employment transition cooperation addition",
    value: ADDITIONAL_ITEM_VALUES.EMPLOYMENT_TRANSITION_COOPERATION,
  },
]
