export * from "./environments"
export * from "./settings"
export * from "./supportBreakDown"
export * from "./utils"
export const DEFAULT_DATE_SIGNATURE_PAD_IMAGE =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXwAAABQCAYAAAAa/s4zAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATRSURBVHgB7d27blRXFAbgDUoZyX4CG2gRmBdAXDoiIgxVoiBhaEBJwaUIIg2CgogUWBSRkgqQQErFRUKhM0bwAECREmE/gf0E5CyTwHBmPJejmTF4fV/FHOyCY/Offdbee+0t7yoFgE1vawEgBYEPkITAB0hC4AMkIfABkhD4AEkIfIAkBD5AEgIfIAmBD5CEwAdIQuADJCHwAZIQ+ABJCHyAJAQ+QBICHyAJgQ+QhMAHSELgAyQh8AGSEPgASQh8gCQEPgN5/fJlAb5MXxXo0907d8qZkyfL9LZtZdfMTDl+4kQ5PDtbaOZ0dS+HaXJyslyfny+b1fPFxTIKu/fsKRPVvctgy7tKgT4cOnCgPH/27MPnH6rA//PWrUIzX28d7gv2VPUg/ufNm7IZLb19W3bu2FFG4cnCQtm7f3/JQEmHvsR/uNawD79cvlyAL4eSDn25duXKJ5/jFbh+rSlvCTAeAp+eYnR/r6rft1pdWWm71pTAf1+OidLCIJarn8uhgwdLRjHgOHzkSGni8aNHa7+/GQl8ejoz5MlFOovJcPoTgd90oLBz+/a0ga+GT1e/37zZVrsHvkxG+KwrSjm/1ur0sTJnvcnaF4uL5fSpUx8+7923r/yhXAOfDYFPRyvVK+83VX14peXVN0oOEfbrlR6WO1xXpuhP1OOHvUwT6vyG0eb/sI8RfqtLXcIe+PwZ4dMmVt/UWyhEKSdKNPWHQKv6RFh87vb1wQMExkfg0+ans2fLxMREuXjhwlpo756ZWbs+6E7H169e9fyeTLscu2myzDB+NrHEMKP4tzfdB7KyulqyEvh0dHxubi2IY0lmTLwOa5MVnTVZZhhvTwKfQQh81hXllidPnxaGL+s6cDaWwKeRqOnX6+/1Hbnx9/F13UwlreGv1AJ/enq6wKgJfBqJ1sj12nu0r20N/KkqxDRY62y5Npm9vLRU7t2+XQaRuRYdJbC/7t8vTUSZcqm63xkJfNgAq7Wwjrej1k1rdBeBb7J/cNbhwwYY1WEe0I3Ahw3gqEg2gpIObWK5W33D1ItaA7VrV6+WqQ4tk1vFOvx+j/GLY+Zi/X8GnQ6TGbQ1coj7/d2xYwX6JfBpE+WGXh0y+ylJDNIzPyYxswR+/d7GDuYm9eheu5ihTkkHxqytA+ncXIFxMMKHMbpbvfHUR+YxwmcwWis0I/Bp03R3bZR5Wo/ciyCzU/ej9c4X0EBucForNCPwYUyiGV19dN/PxrRY0RPrzuuy9tGhOYEPYxC7Ox8/fPjJtZik7md0H8Hez2hWewZ6EfgwYlF+aOudUwX9pT7bTsSDIc4W7tVw7fDsbMki3nh+u3GjNPHzf22/MxL4MGJrfV8ePCgXz59fC+4I+78XFspkhzLNet8fvfK7LXGN+ZIsy1pD3JOmq5vibUngAyN1fX7+fVA1mKj9tgr8OCS+tbto/Dk2rMUBNZu9r0w8HFvnOyb6fFh28uO5c58EfqaOrVveVQoMQUwufn/06IfPu6owipEt8HkQ+ABJ2GkLkITAB0hC4AMkIfABkhD4AEkIfIAkBD5AEgIfIAmBD5CEwAdIQuADJCHwAZIQ+ABJCHyAJAQ+QBICHyAJgQ+QhMAHSELgAyQh8AGSEPgASQh8gCQEPkASAh8giX8B6xyRDzyxq8IAAAAASUVORK5CYII="

// old signature pad image
// "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXwAAABQCAYAAAAa/s4zAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMvSURBVHgB7d3hMSNhHMfxJzf3ngqogAqogAqogAqogAroQAVUQAVUQAcquPPPDBdkd5MIN/w+nxkzN5e8esZ+d/PfZ2P050kD4Mf71QCIIPgAIQQfIITgA4QQfIAQgg8QQvABQgg+QAjBBwgh+AAhBB8ghOADhBB8gBCCDxBC8AFCCD5ACMEHCCH4ACEEHyCE4AOEEHyAEIIPEELwAUIIPkAIwQcIIfgAIQQfIITgM5PLy8t2d3fXgO9r9OdJgwEbGxvt+Pi47e3tNZbj5OSkPTw8DL7v9PS0raysNIY9Pj6OL05msbu7G7euvxsMODs7G4fp/v5+HKk+29vbbWtrqzHs5uZmHKg6mU5Tr11dXY1PtII/m1qzw8PDtra21vu++n1eX1+P+10VfHrVgXF+ft52dnZeXY1WiCpUbw+sOuCYXa1rBX2aWu9aZ+Y3NH5MPYEKPr0ODg7GUb+4uHj1/xWqGu/s7+834HsQfDo9z5hdZX6eOpHWaGcan5ZYNsGn02g0GgepZvhvo18xqlFE3VCcVO8bmp/yT43F6ubhNLXGR0dHDZZF8On0HJsKT0V8ctZcr9UNr7exEvv5VPC7dj7VpyvBX0yNHHlP8JlJhXxyR0Pd9KpY2ZHzMXbffI6he0tdY7SfTvDhP6mdJPVTu6BYrqHnRWozQiLBZ6rb29uXED1fDU0eJDVu6LrhWKMfo51+FfoaldUadl3l1+sedGOZBJ+pVldXX/49bWzTt4PE7pJh19fX45FY36x5lqdwYR6Cz1R1hd43arAPf3HPD7N1PXDFx9VT4bwn+PDFaptrGRrX+KS0uM3NzcZ7gg9fqO559D1sVSeDySv/+qTlfsj8hr5aoev7i34635bJQuqAqhDZVji/Gul0Rbyu6mtta13rR+zn17e+6QQfIIQ/gAIQQvABQgg+QAjBBwgh+AAhBB8ghOADhBB8gBCCDxBC8AFCCD5ACMEHCCH4ACEEHyCE4AOEEHyAEIIPEELwAUIIPkAIwQcIIfgAIQQfIITgA4QQfIAQgg8QQvABQgg+QAjBBwgh+AAhBB8ghOADhBB8gBCCDxBC8AFCCD5AiL+SlPl4aS2xaQAAAABJRU5ErkJggg=="
export const DOB_START_YEAR = 1950
