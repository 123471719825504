import { API } from "@project/common"

export type AdultAttendanceParam = {
  action: "checkin" | "checkout" | "absent"
  time: string
  date: string
}
export type AdultBreakParam = {
  action: "break_start" | "break_end"
  time: string
  date: string
}

export interface DailyAttendanceData {
  data: Data
}

export interface Data {
  attendance_data: AttendanceData
  is_scheduled: boolean
}

export interface AttendanceData {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  facility_id: number
  user_id: number
  user: any // TODO: Type this attrib if we need it in future
  date: string
  save_staff_id: any
  attendance_type: string
  absence_reason: any
  service_type: number
  start_time: string
  end_time: any
  break_start_time: string
  break_start_time2: string
  break_start_time3: string
  break_start_time4: string
  break_start_time5: string

  break_end_time: string
  break_end_time2: string
  break_end_time3: string
  break_end_time4: string
  break_end_time5: string

  remarks: any
  remarks_output: any
  temperature: any
  meal_id: any
  digital_signature_image: any
  digital_signature_date_image: any
}

type UpdateAttendanceFn = (
  params: AdultAttendanceParam | AdultBreakParam,
) => Promise<unknown>
type GetTodayAttendanceFn = (todayDate: string) => Promise<DailyAttendanceData>

export const updateAttendance: UpdateAttendanceFn = (params) =>
  API.post(`/adult-user/my-attendance`, params)

export const getTodayAttendance: GetTodayAttendanceFn = (todayDate) =>
  API.get(`/adult-user/my-attendance/${todayDate}`)
