import {
  CloseCircleFilled,
  DeleteTwoTone,
  PlusCircleFilled,
  UnlockOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import { Button as AntButton, ButtonProps as AntButtonProps } from "antd"
import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import EditIcon from "../../assets/icons/Edit.svg"
import SearchIcon from "../../assets/icons/Search.svg"
import DownArrow from "../../assets/icons/arrow-down.svg"
import BackWardArrow from "../../assets/icons/backward-arrow.svg"
import CheckAll from "../../assets/icons/check-all.svg"
import FrontwardArrow from "../../assets/icons/frontward-arrow.svg"
import Lock from "../../assets/icons/lock.svg"
import Next from "../../assets/icons/next.svg"
import IconPlus from "../../assets/icons/plus.svg"
import Prev from "../../assets/icons/prev.svg"

import BulkPrint from "../../assets/icons/BulkPrint"
import CSV from "../../assets/icons/CSV"
import Calendar from "../../assets/icons/Calendar"
import Paper from "../../assets/icons/Paper"
import PlusCircleBlack from "../../assets/icons/PlusIconBlack.svg"
import PrintDisable from "../../assets/icons/PrintDisable.svg"
import XFileIcon from "../../assets/icons/XFileIcon"
import ADD from "../../assets/icons/add.svg"
import BLUE_PENCIL from "../../assets/icons/blue-pencil.svg"
import BusinessPrint from "../../assets/icons/business-print.svg"
import CopyDark from "../../assets/icons/copy-dark.svg"
import COPY from "../../assets/icons/copy-icon.svg"
import PDF from "../../assets/icons/download.svg"
import EditPrint from "../../assets/icons/edit-gray.svg"
import OrderList from "../../assets/icons/list.svg"
import PlusCircle from "../../assets/icons/pluscircle.svg"
import PrintGrayIcon from "../../assets/icons/print-gray.svg"
import { theme } from "../../theme"

type ButtonVariant =
  | AntButtonProps["type"]
  | "faded"
  | "secondary"
  | "grayed"
  | "outlined"
  | "success"
  | "error-text"
  | "error"
  | "error-outlined"
  | "outlined-white-bg"

interface ButtonProps extends Omit<AntButtonProps, "type"> {
  btnText: string
  iconType?:
    | "search"
    | "print"
    | "edit"
    | "copy"
    | "check-all"
    | "plus"
    | "prev"
    | "next"
    | "down-arrow"
    | "frontward-arrow"
    | "backward-arrow"
    | "print-gray"
    | "print"
    | "pdf"
    | "blue-pencil"
    | "copy"
    | "delete"
    | "add"
    | "print-disable"
    | "plus-circle"
    | "lock"
    | "remove"
    | "plus-black"
    | "delete-x"
    | "bulk-print"
    | "business-print"
    | "copy-dark"
    | "upload"
    | "edit-gray"
    | "order-list"
    | "csv"
    | "calendar"
    | "paper"
    | "XFileIcon"
    | "unlock"
    | "plus_circle"
  btnBg?: string
  hoverBtnBg?: string
  borderColor?: string
  textColor?: string
  hoverTextColor?: string
  onClick?: (e: any) => void
  height?: string
  width?: string
  otherType?: "draft"
  maxHeight?: string
  isLoading?: boolean
  padding?: string
  type?: ButtonVariant
  rightIcon?: ReactNode
  rightIconMl?: string
  hoverBorderColor?: string
}
const StyledButton = styled(AntButton)<ButtonProps>`
  display: flex;
  align-items: center;
  box-shadow: ${theme.shadow.btnShadow};
  min-height: ${(props) => props?.height || "36px"} !important;
  color: ${(props) => props?.textColor || ""};
  text-align: center;
  height: ${(props) => props?.maxHeight || "auto"} !important;
  word-wrap: break-word;
  white-space: initial;
  justify-content: center;
  padding: ${(props) => props?.padding || " "}!important;
  &:hover,
  &:active {
    background: ${(props) => props?.hoverBtnBg || props?.btnBg} !important;
    color: ${(props) => props?.hoverTextColor || props?.textColor} !important;
    border-color: ${(props) =>
      props?.hoverBorderColor || theme.colors.action} !important;
  }
  ${(props) => props?.width && `width:${props.width}`};
  span {
    ${theme.typography["bnt-text"]};
    margin-inline-end: ${(props) =>
      props.iconType == "frontward-arrow" ? "8px" : "0px"};
  }
  .ant-btn-round,
  .ant-btn.ant-btn-round.ant-btn-lg {
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
  }

  ${(props) =>
    props?.type === "primary" &&
    css`
      border-color: ${props?.borderColor
        ? props?.borderColor
        : theme.colors.action};
      background: ${props?.btnBg ? props?.btnBg : theme.colors.action};
    `}
  ${(props) =>
    !props?.type &&
    css`
      border-color: ${props?.borderColor
        ? props?.borderColor
        : theme.colors.action};
      background: ${props?.btnBg ? props?.btnBg : "#F6F7F8"};
    `};
  ${(props) =>
    props?.otherType === "draft" &&
    css`
      background: ${props?.btnBg ? props?.btnBg : `rgba(7, 130, 200, 0.2)`};
      color: ${props?.color ? props?.color : theme.colors["sub-text"]};
      border: ${
        props?.borderColor
          ? `1px solid ${props?.borderColor}`
          : "1px solid rgba(7, 130, 200, 1);"
      }
      opacity: 0.8;
    `}
  ${(props) =>
    props?.type === "faded" &&
    css`
      background-color: ${theme.colors.disabled};
      color: ${theme.colors["disabled-text"]};
      border: 1px solid ${theme.colors.border};

      &:hover {
        filter: brightness(102%);
      }
    `}
  ${(props) =>
    props?.type === "secondary" &&
    css`
      background-color: ${theme.colors.cyan1};
      color: ${theme.colors["disabled-text"]};
      border: 1px solid ${theme.colors.cyan1};
      color: ${theme.colors.white};

      &:hover {
        filter: brightness(105%);
        border: 1px solid ${theme.colors.cyan1} !important;
      }
    `}

  ${(props) =>
    props?.type === "grayed" &&
    css`
      background-color: ${theme.colors.disabled};
      border: 1px solid ${theme.colors.border};
      color: ${theme.colors.text};

      &:hover {
        filter: brightness(102%);
        border: 1px solid ${theme.colors.border} !important;
      }

      &:disabled {
        pointer-events: none;
        color: ${theme.colors.border};
      }
    `}

  ${(props) =>
    props?.type === "outlined" &&
    css`
      background-color: ${theme.colors.blue2};
      border: 1px solid ${theme.colors.link};
      color: ${theme.colors.text};

      &:hover {
        filter: brightness(102%);
        border: 1px solid ${theme.colors.link} !important;
      }
    `}

 ${(props) =>
    props?.type === "success" &&
    css`
      background-color: ${theme.colors.success};
      border: 1px solid ${theme.colors.success};
      color: ${theme.colors.white};

      &:hover {
        filter: brightness(102%);
        border: 1px solid ${theme.colors.success} !important;
      }
    `}

${(props) =>
    props?.type === "error" &&
    css`
      background-color: ${theme.colors.error};
      border: 1px solid ${theme.colors.error};
      color: ${theme.colors.white};

      &:hover {
        filter: brightness(102%);
        border: 1px solid ${theme.colors.error} !important;
      }
    `}

${(props) =>
    props?.type === "error-text" &&
    css`
      background-color: transparent;
      border: none;
      color: ${theme.colors.error};
      text-decoration: underline;
    `}

${(props) =>
    props?.type === "error-outlined" &&
    css`
      background-color: transparent;
      border: 1px solid ${theme.colors.error};
      color: ${theme.colors.error};

      &:hover {
        border: 1px solid ${theme.colors.error} !important;
        background-color: #f4f4f4;
      }
    `}

${(props) =>
    props?.type === "outlined-white-bg" &&
    css`
      background-color: white;
      border: 1px solid ${theme.colors.border};
      color: ${theme.colors.text};

      &:hover {
        border: 1px solid ${theme.colors.border} !important;
        background-color: #f4f4f4;
      }
    `}


  @media screen and (max-width: ${theme.breakpoints.sm}) {
    span {
      font-size: 12px;
    }
  }
  ${(props) =>
    props.className === "facility-contact" &&
    css`
      white-space: initial;
      height: auto;
    `}

  cursor: ${(props) => (props?.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => props?.disabled && theme.colors.disabled2};
`

const Button = ({
  btnText,
  isLoading,
  iconType,
  ...props
}: ButtonProps): JSX.Element => {
  const getIcon = () => {
    switch (iconType) {
      case "search":
        return <SearchIcon />
      case "edit":
        return <EditIcon />
      case "print-gray":
        return <PrintGrayIcon />
      case "check-all":
        return <CheckAll />
      case "plus":
        return <IconPlus />
      case "plus_circle":
        return (
          <PlusCircleFilled
            style={{
              color: theme.colors.action,
            }}
          />
        )
      case "prev":
        return <Prev />
      case "next":
        return null
      case "down-arrow":
        return <DownArrow />
      case "backward-arrow":
        return <BackWardArrow />
      case "pdf":
        return <PDF />
      case "print":
        return <BulkPrint />
      case "blue-pencil":
        return <BLUE_PENCIL />
      case "copy":
        return <COPY />
      case "plus-circle":
        return <PlusCircle />
      case "lock":
        return <Lock />
      case "business-print":
        return <BusinessPrint />
      case "delete":
        return <DeleteTwoTone twoToneColor={theme.colors.error} />
      case "add":
        return <ADD />
      case "print-disable":
        return <PrintDisable />
      case "remove":
        return (
          <CloseCircleFilled
            style={{
              color: theme.colors.error,
            }}
          />
        )
      case "plus-black":
        return <PlusCircleBlack />
      case "delete-x":
        return (
          <CloseCircleFilled
            style={{
              color: theme.colors.error,
            }}
          />
        )
      case "bulk-print":
        return <BulkPrint />
      case "copy-dark":
        return <CopyDark />
      case "upload":
        return <UploadOutlined />
      case "edit-gray":
        return <EditPrint />
      case "order-list":
        return <OrderList />
      case "csv":
        return <CSV />
      case "calendar":
        return <Calendar />
      case "paper":
        return <Paper />
      case "XFileIcon":
        return <XFileIcon />
      case "unlock":
        return <UnlockOutlined />
      default:
        return null
    }
  }

  return (
    <StyledButton
      btnText={btnText}
      type={props?.type}
      {...props}
      icon={getIcon()}
      loading={isLoading}
    >
      {btnText} {iconType == "frontward-arrow" && <FrontwardArrow />}
      {iconType === "next" && (
        <span style={{ marginLeft: "12px", marginTop: "6px" }}>
          <Next />
        </span>
      )}
    </StyledButton>
  )
}
Button.displayName = "Button"
interface CircleButtonProps extends AntButtonProps {
  Icon: ReactNode
  width?: string
  height?: string
  borderColor?: string
  background?: string
}
const StyledCircleButton = styled(AntButton)<CircleButtonProps>`
  width: ${(props) => props?.width || "36px"} !important;
  height: ${(props) => props?.height || "36px"};
  border-color: ${(props) => props?.borderColor || theme.colors.action};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props?.background || "transparent"};
`
const CircleButton = ({ Icon, ...props }: CircleButtonProps) => (
  <StyledCircleButton shape={"circle"} icon={Icon} {...props} />
)
CircleButton.displayName = "CircleButton"

const ButtonWithRightIcon = ({
  btnText,
  isLoading,
  rightIcon,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <StyledButton
      btnText={btnText}
      type={props?.type}
      {...props}
      loading={isLoading}
    >
      {btnText}
      {rightIcon && (
        <div style={{ marginLeft: props?.rightIconMl || "6px" }}>
          {rightIcon}
        </div>
      )}
    </StyledButton>
  )
}
ButtonWithRightIcon.displayName = "ButtonWithRightIcon"
export { Button, ButtonWithRightIcon, CircleButton }
export const LinkButton = ({
  children,
  style = {},
  ...props
}: {
  children: ReactNode | string
  onClick?: () => void
  disabled?: boolean
  style?: React.CSSProperties
}) => {
  return (
    <button
      {...props}
      style={{
        textDecoration: "underline",
        whiteSpace: "nowrap",
        color: props?.disabled ? theme.colors.disabled2 : theme.colors?.action,
        border: "none",
        background: "transparent",
        cursor: props?.disabled ? "not-allowed" : "pointer",
        width: "fit-content",
        ...style,
      }}
    >
      {children}
    </button>
  )
}
