import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import _ from "lodash"
import wareki from "wareki"
dayjs.extend(arraySupport)

const getEraAndYears = (year) => {
  const eras = new Set()
  const years = new Set()
  for (let month = 1; month <= 12; month++) {
    const date = dayjs([year, month, 1])
    const era = wareki(date.format("YYYY-MM-DD"))
    eras.add(era)

    if (year === 2019) {
      years.add("2019-1")
      years.add("2019-2")
    } else if (year === 1989) {
      years.add("1989-1")
      years.add("1989-2")
    } else {
      years.add(`${year}`)
    }
  }
  return {
    era: Array.from(eras),
    years: Array.from(years),
  }
}
export const generateJapaneseEraAndYears = (down?: number, up?: number) => {
  let startYear = down || 1989
  const endYear = up || new Date().getFullYear()
  const eras = []
  const years = []
  while (startYear <= endYear) {
    const data = getEraAndYears(startYear)
    eras.push(data?.era)
    years.push(data?.years)
    startYear++
  }
  const flat_eras = _.uniq(_.flatten(eras))
  const flat_years = _.uniq(_.flatten(years))
  const options = flat_eras
    ?.slice(0, flat_eras?.length - 1)
    .map((val, index) => ({
      label: `${val}(${flat_years[index]})`,
      value: flat_years[index],
    }))
  return options || []
}
//generate months
export const generateMonthsAccordingToEra = (year, month) => {
  const months = []
  for (let index = 0; index <= 11; index++) {
    months?.push({
      label: index + 1,
      value: `0${index + 1}`.slice(-2),
    })
  }
  if (year === 2019 && month <= 4) {
    return months?.slice(0, 4)
  }
  if (year === 2019 && month >= 5) {
    return months?.slice(4)
  }

  return months
}
