import { API, removeBlankAttributes } from "@project/common"

export interface IReturnUser {
  count?: number
  data?: any
}

export const fetchProgramRecord = (queryParams: {
  page: number
  pageSize: number
  month?: number
  year?: number
}): Promise<IReturnUser> => {
  const params = removeBlankAttributes(queryParams)
  return API.get(`/adult-user/program-record`, {
    params,
  })
}

export const fetchProgramRecordDetail = (id: string): Promise<IReturnUser> => {
  return API.get(`/adult-user/program-record/${id}`)
}
