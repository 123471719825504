import ReactDOMServer from "react-dom/server"
export const usePdfDownload = ({
  mainContent,
  pageBreak,
  orientation,
  margin,
  filename,
  styles = {},
  scale = 2,
}: {
  mainContent: any
  margin?: number
  filename: string
  orientation?: string
  pageBreak?: any
  styles?: any
  scale?: number
  quality?: number
}) => {
  const handlePrintPDF = async () => {
    const html2pdf = (await import("html2pdf.js/dist/html2pdf.min.js")).default
    const opt = {
      margin: margin || 4,
      filename: filename ? `${filename}.pdf` : "filename.pdf",
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: { scale },
      pagebreak: pageBreak || { mode: ["css", "legacy"] },
      jsPDF: {
        orientation: orientation || "p",
        unit: "mm",
        format: "a4",
        compress: true,
        precision: 12,
        putOnlyUsedFonts: true,
        compressPDF: true,
      },
    }
    if (window !== undefined) {
      const printElement =
        ReactDOMServer.renderToString(mainContent()) +
        `  <style>
           * {
            font-size: 12px !important;
          }
          ${styles};
        </style>`
      html2pdf().set(opt).from(printElement).save()
    }
  }
  return { handlePrintPDF }
}
