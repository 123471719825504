import { API } from "@project/common"

export interface IReturnServiceProvision {
  count?: number
  data?: any
}

export const getServiceProvisionRecordSheet = ({
  month,
  year,
  facility_id,
  show_no_addition_absence,
  show_actual_expenses,
  additional_items,
}): Promise<IReturnServiceProvision> => {
  return API.get(`/adult-user/service-provision/${year}/${month}`, {
    params: {
      facility: facility_id,
      show_no_addition_absence: show_no_addition_absence,
      show_actual_expenses: show_actual_expenses,
      additional_items: additional_items,
    },
  })
}
