import React from "react"
import { DetailInfoContent } from "./PlanDetailContent.style"
import { Grid } from "../../components"
import { useTranslation } from "react-i18next"
import { Typography } from "antd"

export const PlanDetailInfoContent = ({
  details,
}: {
  details: any
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <DetailInfoContent>
      <Grid
        labelContent={t("Intention of the person/family")}
        background
        labelSpan={6}
      >
        <Typography.Text style={{ whiteSpace: "pre-line" }}>
          {details?.user_family_hope}
        </Typography.Text>
      </Grid>
      <Grid
        labelContent={t("Comprehensive support policy")}
        background
        labelSpan={6}
      >
        <Typography.Text style={{ whiteSpace: "pre-line" }}>
          {details?.support_policy}
        </Typography.Text>
      </Grid>
      <Grid labelContent={t("Long term goal")} background labelSpan={6}>
        {" "}
        <Typography.Text style={{ whiteSpace: "pre-line" }}>
          {details?.long_term_goal}
        </Typography.Text>
      </Grid>
      <Grid labelContent={t("Short term goal")} background labelSpan={6}>
        <Typography.Text style={{ whiteSpace: "pre-line" }}>
          {details?.short_term_goal}
        </Typography.Text>
      </Grid>
      <Grid labelContent={t("Transportation")} background labelSpan={6}>
        <div id={"transportation_info"}>
          <Typography.Text>
            {t("Pick up")} {":"}
            {details?.goto_meet_flag ? details?.goto_meet_place : t("None")}
          </Typography.Text>
          <Typography.Text>
            {t("Sending")} {":"}
            {details?.take_flag ? details?.take_place : t("None")}
          </Typography.Text>
        </div>
      </Grid>
    </DetailInfoContent>
  )
}
