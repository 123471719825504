import { Checkbox } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "../../theme"

const StyledWrapper = styled.div`
  width: 100%;
  background-color: white;
  .assessment_print_content_table {
    width: 100%;
    border-collapse: collapse;
    tbody {
      tr {
        /* page-break-inside: avoid !important; */
        td {
          border: 1px solid ${theme.colors.border};
          page-break-inside: avoid !important;
          padding: 3px 4px;
          font-size: 7pt;
          .item_content-title {
            padding-bottom: 3px;
            font-size: 8pt;
            &:not(:first-child) {
              padding-top: 2px;
            }
          }
        }
        .label {
          width: 25%;
          background-color: ${theme.colors.background};
        }
      }
    }
  }
`
type TProps = {
  title: string
  items: FieldProps[]
}
interface FieldProps {
  label: string
  value: string | TProps[] | React.ReactNode
  [x: string]: any
}
const PrintFriendlyGridTable = ({
  fields,
}: {
  fields: FieldProps[]
}): JSX.Element => {
  const { t } = useTranslation()
  const renderTable = (data: FieldProps[]) => (
    <StyledWrapper>
      <table className={"assessment_print_content_table"}>
        <tbody>
          {data?.map((field, index) => (
            <tr key={index}>
              <td className={"table__cell label"}>{t(field?.label)}</td>
              <td className={"table__cell value"}>
                {Array.isArray(field?.value) ? (
                  <div className={"employment_item_content"}>
                    {field?.value?.map((item, index) => (
                      <>
                        <div className={"item_content-title"}>
                          {item?.title || ""}
                        </div>
                        <div key={index}>{renderTable(item?.items || [])}</div>
                      </>
                    ))}
                  </div>
                ) : field?.type === "checkbox" ? (
                  <>
                    <Checkbox
                      type={"checkbox"}
                      checked={field?.value ? true : false}
                      disabled
                    >
                      {t("hope")}
                    </Checkbox>
                  </>
                ) : (
                  <span style={{ whiteSpace: "pre-wrap" }}>{field?.value}</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledWrapper>
  )

  return renderTable(fields || [])
}
PrintFriendlyGridTable.Name = "PrintFriendlyGridTable"
export { PrintFriendlyGridTable }
