import { AppProps } from "next/app"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

// packages
import * as Sentry from "@sentry/node"
import jaJP from "antd/lib/locale/ja_JP"
import { ConfigProvider, message } from "antd"
import NextNProgress from "nextjs-progressbar"
import { useTranslation } from "react-i18next"
import { CloseCircleFilled } from "@ant-design/icons"
import { ReactQueryDevtools } from "react-query/devtools"
import { onAuthStateChanged, signOut } from "firebase/auth"
import { QueryClient, QueryClientProvider } from "react-query"
import { setCurrentScreen, logEvent } from "firebase/analytics"

// commons | components
import {
  API,
  auth,
  i18next,
  analytics,
  LanguageOption,
  DEV_ENVIRONMENTS,
} from "@project/common"

// contexts
import { AuthProvider } from "../contexts/AuthProvider"

// utils
import "../utils/css-imports"
import { GlobalStyles } from "../utils"
import { ConsumerThemeConfig } from "../utils/theme.config"

// types
import { FacilityMe, Me } from "../types/users.types"

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `consumer-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const queryClient = new QueryClient({ defaultOptions: {} })
const MyApp = ({ Component, pageProps }: AppProps) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [isUser, setIsUser] = useState(false)
  const [facilities, setFacilities] = useState<FacilityMe["data"]>(null)
  const [userInformation, setUserInformation] = useState<Me>(null)
  const [isInfoLoading, setIsInfoLoading] = useState(true)

  const routers = useRouter()

  // in case of exceptional error in authorization we log user out of the page.
  const logOut = () => {
    signOut(auth)
    setUser(null)
    window.location.href = "/login"
  }

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logAnalyticsEvent = (url: string) => {
        setCurrentScreen(analytics, url)
        logEvent(analytics, "screen_view", {
          firebase_screen: url,
          firebase_screen_class: "skeleton-consumer",
        })
      }

      routers.events.on("routeChangeComplete", (url) => {
        logAnalyticsEvent(url)
      })

      logAnalyticsEvent(window.location.pathname)
      return () => {
        routers.events.off("routeChangeComplete", logAnalyticsEvent)
      }
    }
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user !== null) {
          const idToken = await user!.getIdTokenResult()
          if (idToken.claims["company_adult"]) {
            setUser(user)
            setIsUser(true)
            const facilitiesResponse = await API.get(
              "/adult-user/me-facilities",
            )
            setFacilities(facilitiesResponse.data)
            const userInfo = (await API.get("/adult-user/me")) as any

            // Log out user if no active contract facilities found
            if (!userInfo?.active_contract) {
              message.error({
                key: "2",
                icon: (
                  <CloseCircleFilled onClick={() => message.destroy("2")} />
                ),
                content: t(
                  "You cannot login at this moment. Please contact your administrator.",
                ),
              })

              signOut(auth)
              setUser(null)
            }
            setUserInformation(userInfo)
            setIsInfoLoading(false)
            if (
              userInfo &&
              !userInfo?.data?.my_page_setting?.activity_record &&
              routers.pathname === "/"
            ) {
              routers.push("/")
            }
          } else {
            signOut(auth)
            setUser(null)
            message.error({
              key: "01",
              icon: <CloseCircleFilled onClick={() => message.destroy("01")} />,
              content: t("Unauthorized user"),
            })
          }
        }
        setLoading(false)
      } catch (error) {
        Sentry.captureException(error)
        message.error({
          key: "02",
          content: t("Something went wrong. Please contact administrator"),
          icon: <CloseCircleFilled onClick={() => message.destroy("02")} />,
        })
        setIsInfoLoading(false)
        setLoading(false)
      }
    })
    return () => unsubscribe && unsubscribe()
  }, [])
  return (
    <>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <NextNProgress
          color={"#77D5F7"}
          height={8}
          showOnShallow={true}
          options={{
            showSpinner: false,
          }}
        />
        <AuthProvider
          loading={loading}
          user={user}
          isOwner={isUser}
          setUser={setUser}
          logOut={logOut}
          facilities={facilities}
          userInformation={userInformation}
          isInfoLoading={isInfoLoading}
          queryClient={queryClient}
        >
          <ConfigProvider
            locale={i18next?.language === "ja" && jaJP}
            theme={ConsumerThemeConfig}
          >
            <Component {...pageProps} />
            {DEV_ENVIRONMENTS.includes(process.env.NEXT_PUBLIC_ENVIRONMENT) ? (
              <LanguageOption />
            ) : (
              <></>
            )}
          </ConfigProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}

export default MyApp
