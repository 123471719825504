import React from "react"
import type { ColumnsType } from "antd/es/table"
import { useTranslation } from "react-i18next"
import { Table } from "../Table/Table"
import { theme } from "../../theme"

export const PlanContentGoals = ({
  defaultData,
  headerBg,
}: {
  defaultData: any[]
  headerBg?: string
}): JSX.Element => {
  const { t } = useTranslation()
  const columns: ColumnsType<any[]> = [
    {
      key: "items",
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {t("Items (personal needs, etc.)")}
        </span>
      ),
      render: (row) => {
        return {
          props: {
            style: {
              background: theme.colors.background,
              borderColor: theme.colors.border,
            },
          },
          children: row?.title,
        }
      },
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Specific goals")}</span>
      ),
      key: "achivement_goal",
      render: (row) => (
        <>
          <ul style={{ marginLeft: "1rem" }}>
            {row?.support_content?.achievement_goals?.map(
              (val, i) =>
                val && (
                  <li key={i} style={{ whiteSpace: "pre-line" }}>
                    {` ${val}`}
                  </li>
                ),
            )}
          </ul>
        </>
      ),
    },
    {
      title: t("Contents of support/Points to consider"),
      key: "consideration",
      render: (row) => (
        <>
          <ul style={{ marginLeft: "1rem" }}>
            {row?.support_content?.support_and_considerations?.map(
              (val, i) =>
                val && (
                  <li key={i} style={{ whiteSpace: "pre-line" }}>
                    {` ${val}`}
                  </li>
                ),
            )}
          </ul>
        </>
      ),
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{t("Achievement time")}</span>
      ),
      key: "achievement_time",
      width: "200px",
      render: (row) => (
        <>
          <ul style={{ marginLeft: "1rem" }}>
            {row?.support_content?.achievement_time?.map(
              (val, i) =>
                val && (
                  <li key={i} style={{ whiteSpace: "pre-line" }}>
                    {` ${val}`}
                  </li>
                ),
            )}
          </ul>
        </>
      ),
    },
  ]
  return (
    <Table
      columns={columns}
      dataSource={defaultData || []}
      scroll={{ x: 500 }}
      headerBg={headerBg}
    />
  )
}
