import Image from "next/image"

// packages
import { useFormik } from "formik"
import { t } from "i18next"
import * as yup from "yup"

// commons | components
import { Box, Button, InputField } from "../../components"

// styles
import { Flex } from "antd"
import Link from "next/link"
import { theme } from "../../theme"
import { ResetFormContainer } from "./ForgotPassword.styles"

// Validation schema
export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .test("is-valid-email", t("Enter a valid Email"), function (value) {
      const { path, createError } = this
      // Check if the field is empty
      if (!value || !value.trim()) {
        return createError({ path, message: t("Please enter") })
      }

      // Regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(value)
        ? true
        : createError({ path, message: t("Enter a valid Email") })
    }),
})

// Inferred Type
type ResetValue = yup.InferType<typeof validationSchema>

export const ForgotPassword = ({
  info,
  handleSubmit,
  btnProps = {},
}: {
  info?: string
  handleSubmit: (value: ResetValue) => void
  btnProps?: any
}) => {
  const formik = useFormik<ResetValue>({
    initialValues: {
      email: "",
    },
    validationSchema,
    // eslint-disable-next-line no-console
    onSubmit: (value) => {
      handleSubmit(value)
    },
  })

  return (
    <Box
      display={"flex"}
      direction={"column"}
      justify={"center"}
      align={"center"}
      h={"100vh"}
      w={"100%"}
      px={16}
    >
      <Image
        src={"/assets/logo.svg"}
        alt={t("logo")}
        height={100}
        width={100}
      />

      <Box component={"h3"} mb={42} mt={16} fz={"calc(18px)"} fw={700}>
        {t("Forgot password")}
      </Box>

      <ResetFormContainer
        w={{
          "4xs": "100%",
          xs: "427px",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box mb={20} maw={"calc(368px)"}>
            <InputField
              name={"email"}
              height={"48px"}
              width={"368px"}
              label={t("Email")}
              bg={"transparent"}
              borderRadius={"8px"}
              className={"text-field"}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder={t("Enter Email")}
              error={
                formik.touched.email && formik.errors.email
                  ? t(formik.errors.email)
                  : null
              }
              info={t(info || "")}
            />
          </Box>

          <Button
            width={"100%"}
            size={"large"}
            btnText={t("Submit")}
            htmlType={"submit"}
            {...btnProps}
          />

          <Flex
            align={"center"}
            justify={"center"}
            style={{
              pointerEvents: btnProps?.isLoading ? "none" : "auto",
            }}
          >
            <Link href={"/login"}>
              <a
                style={{
                  textDecoration: "underline",
                  marginTop: "4px",
                  color: theme.colors.action,
                }}
              >
                {t("Go to Login")}
              </a>
            </Link>
          </Flex>
        </form>
      </ResetFormContainer>
    </Box>
  )
}
