import { useRef } from "react"
import styled, { css } from "styled-components"
import { theme } from "../../theme"
const commonStyles = css`
  .top_scroll_bar_container,
  .main_content_container {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .top_scroll_bar_container {
    height: 20px;
  }
`
const StyledHorizontalScroller = styled.div`
  width: 100%;
  max-width: 100%;
  @media screen and (max-width: ${theme.breakpoints.xxl}) {
    ${commonStyles},
    .top__scrollbar {
      width: 1200px;
      overflow-x: scroll;
      overflow-y: hidden;
      height: 20px;
    }
    .children__content {
      width: 1400px;
      height: fit-content;
    }
  }
`
export const HorizontalScroller = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const topScrollbarRef = useRef(null)
  const contentRef = useRef(null)

  // Synchronize the scroll position of the top and bottom scrollbars
  const syncScroll = (source) => {
    if (source === "top") {
      contentRef.current.scrollLeft = topScrollbarRef.current.scrollLeft
    } else if (source === "content") {
      topScrollbarRef.current.scrollLeft = contentRef.current.scrollLeft
    }
  }

  return (
    <StyledHorizontalScroller>
      <div
        className={"top_scroll_bar_container"}
        onScroll={() => syncScroll("top")}
        ref={topScrollbarRef}
      >
        <div className={"top__scrollbar"}></div>
      </div>
      <div
        className={"main_content_container"}
        ref={contentRef}
        onScroll={() => syncScroll("content")}
      >
        <div className={"children__content"}>{children}</div>
      </div>
    </StyledHorizontalScroller>
  )
}
