import styled from "styled-components"
import { theme } from "../theme"
interface ActionBtnsProps {
  justify?: string
  mt?: string
}
export const ActionBtns = styled.div<ActionBtnsProps>`
  @media print {
    display: none;
  }
  display: flex;
  row-gap: 1em;
  column-gap: 1em;
  justify-content: ${(props) => props?.justify || "space-between"};
  margin-top: ${(props) => props?.mt || ""};
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    & > div > * {
      margin-bottom: 8px;
    }
  }
  .button_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
  .button_content {
    display: flex;
    gap: 1em;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  }
  .btn_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .btn_content {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .delete_btn {
    button {
      border: none;
      background-color: ${theme.colors.error};
      color: ${theme.colors.white};
      padding: 10px 30px;
    }
    button:hover {
      color: white !important;
    }
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
  .first-btn-content {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
  }
  .save-cancel-buttons {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    margin-top: 16px;
  }
  .delete-button {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const StyledColGap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
`

export const OperationOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  .fields {
    display: flex;
    row-gap: 5px;
  }
  .field__content {
    display: flex;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
  .user_select {
    display: flex;
    gap: 10px;
  }
  .search__content {
    display: flex;
    align-items: center;
    .button_accordion {
      span {
        margin-inline-end: 0px;
      }
    }
    .search__content-date {
      display: flex;
      align-items: center;
      row-gap: 0.5em;
      column-gap: 1em;
      flex-wrap: wrap;
      .date-content {
        display: flex;
        align-items: center;
        row-gap: 0.8em;
        column-gap: 0.8em;
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          row-gap: 0.3em !important;
          column-gap: 0.3em !important;
        }
        @media screen and (max-width: ${theme.breakpoints.xs}) {
          align-items: flex-start;
          width: 100%;
          flex-wrap: wrap !important;
        }
      }
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
  .fiscal_year {
    gap: 0px;
  }
  .user_name_select {
    display: flex;
    gap: 10px;
  }
`
export const OperationSearchContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.space.default};
  .display_button {
    .ant-btn {
      white-space: nowrap !important;
    }
  }
  .search__content {
    display: flex;
    gap: 1em;
    align-items: center;
    .flex {
      display: flex;
      row-gap: 16px;
      column-gap: 16px;
      @media screen and (max-width: ${theme.breakpoints.xmd}) {
        width: 100%;
      }
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        flex-wrap: wrap;
      }
    }
    .checkbox {
      display: flex;
      flex-wrap: wrap !important;
      row-gap: 10px;
    }
    .ant-radio-group {
      display: flex;
      flex-wrap: nowrap;
      @media screen and (max-width: ${theme.breakpoints.sm}) {
        flex-wrap: wrap;
      }
    }
    .search__content-date {
      display: flex;
      align-items: center;
      gap: 0.5em;
      flex-wrap: wrap;
      .date-content {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        @media screen and (max-width: ${theme.breakpoints.sm}) {
          flex-wrap: wrap;
        }
      }
    }

    @media screen and (max-width: ${theme.breakpoints.sm}) {
      align-items: flex-start;
      flex-direction: column;
      .w-full {
        width: 100% !important;
      }
      .no_row_gap {
        row-gap: 0px !important;
      }
    }
  }

  .flex-start {
    align-items: flex-start !important;
    .mt {
      padding-top: 0.4em;
    }
    .mt-sm {
      padding-top: 0.1em;
    }
  }
  @media only print {
    display: none;
  }

  .hide-phase-2 {
    display: none;
  }
`
export const OpFlexContainer = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
  align-items: center;
  .flex__content {
    display: flex;
    gap: 1em;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
    }
  }
  @media screen and (max-width: 1406px) {
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-start;
  }
`
export const TimeRangeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 16px;
  .time_range {
    display: flex;
    align-items: center;
    column-gap: 4px;
    .from__time,
    .to__time {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
    @media screen and (max-width: ${theme.breakpoints["3xs"]}) {
      flex-wrap: wrap;
      row-gap: 4px;
    }
  }
`
export const IndicatorBox = styled.div<{ $bg?: string; $radius?: string }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
  .box {
    height: 28px;
    width: 28px;
    border-radius: ${(props) => props?.$radius || "5px"};
    background-color: ${(props) => props?.$bg || theme.colors.pink};
  }
  .text {
    font-size: 16px;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
`
