import { Typography } from "antd"
import dayjs from "dayjs"
import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { FACILITY_TYPES, getCurrentJapaneseEra } from "../../../utils"
import {
  PrintSummaryContentTable,
  PrintSummaryFooter,
  PrintSummaryGoalsContentTable,
  PrintSummaryTable,
  StyledPrintPageWrapper,
} from "./PrintPage.style"
interface Props {
  data: any
  goals?: any[]
  operationData?: any
  title?: string
}
const PlanPrintPage = (
  { data, goals, operationData, title }: Props,
  ref: any,
): JSX.Element => {
  const { t } = useTranslation()
  const renderSignature = (url) => (
    <>{url ? <img src={url} alt={"signature"} id={"sign-img"} /> : ""}</>
  )

  return (
    <StyledPrintPageWrapper ref={ref}>
      <div className={"title"}>
        {title || "久保 結月さんの個別支援計画(原案)"}
      </div>
      <div className={"text__info"}>
        <div>
          <Typography.Text strong>
            {t("Facility name")} {": "}
          </Typography.Text>
          <Typography.Text>{data?.facility?.facility_name}</Typography.Text>
        </div>
        <div>
          <Typography.Text strong>
            {t("Use service")} {": "}
          </Typography.Text>
          <Typography.Text>
            {t(
              FACILITY_TYPES?.find(
                (type) => type?.value === data?.use_service_id,
              )?.label,
            )}
          </Typography.Text>
        </div>
        <div>
          <Typography.Text strong>
            {t("Created date")} {": "}
          </Typography.Text>
          <Typography.Text>
            {data
              ? dayjs(data?.created_datetime).format("YYYY年MM月DD日")
              : dayjs().format("YYYY年MM月DD日")}
          </Typography.Text>
        </div>
      </div>
      <PrintSummaryTable>
        <table id={"plan_detail_summary"}>
          <tbody>
            <tr>
              <td>{t("Recipient number")}</td>
              <td> {data?.user_certificate_number}</td>
              <td>{t("StartDate")}</td>
              <td>
                {dayjs(data?.start_date).isValid()
                  ? dayjs(data?.start_date).format("YYYY年MM月DD日")
                  : ""}
              </td>
              <td>{t("Expiry date")}</td>
              <td>
                {" "}
                {dayjs(data?.end_date).isValid()
                  ? dayjs(data?.end_date).format("YYYY年MM月DD日")
                  : ""}
              </td>
              <td>{t("No. of created")}</td>
              <td>{data?.create_count}</td>
            </tr>
          </tbody>
        </table>
      </PrintSummaryTable>
      <PrintSummaryContentTable>
        <table id={"efa__table"}>
          <tbody>
            <tr>
              <td>{t("Intention of the person/family")}</td>
              <td>{data?.user_family_hope}</td>
            </tr>
            <tr>
              <td>{t("Comprehensive support policy")}</td>
              <td>{data?.support_policy}</td>
            </tr>
            <tr>
              <td>{t("Long term goal")}</td>
              <td>{data?.long_term_goal}</td>
            </tr>
            <tr>
              <td>{t("Short term goal")}</td>
              <td>{data?.short_term_goal}</td>
            </tr>
            <tr>
              <td>{t("Transportation")}</td>
              <td>
                <div id={"transportation_info"}>
                  <Typography.Text>
                    {t("Pick up")} {":"}
                    {data?.goto_meet_flag ? data?.goto_meet_place : t("None")}
                  </Typography.Text>
                  <Typography.Text>
                    {t("Sending")} {":"}
                    {data?.take_flag ? data?.take_place : t("None")}
                  </Typography.Text>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </PrintSummaryContentTable>
      <PrintSummaryGoalsContentTable>
        <table id={"plan_detail_goals"}>
          <thead>
            <tr>
              <th>{t("Items (personal needs, etc.)")}</th>
              <th>{t("Specific goals")}</th>
              <th>{t("Contents of support/Points to consider")}</th>
              <th>{t("Achievement time")}</th>
            </tr>
          </thead>
          <tbody>
            {goals?.map((goal, idx) => (
              <tr key={idx}>
                <td>{goal?.title}</td>
                <td>
                  {goal?.support_content?.achievement_goals?.map(
                    (val, i) =>
                      val && (
                        <div key={i} className={"list__item"}>
                          <div className={"dot"}></div>
                          <div style={{ whiteSpace: "pre-line" }}>
                            {` ${val}`}
                          </div>
                        </div>
                      ),
                  )}
                </td>
                <td>
                  {goal?.support_content?.support_and_considerations?.map(
                    (val, i) =>
                      val && (
                        <div key={i} className={"list__item"}>
                          <div className={"dot"}></div>
                          <div style={{ whiteSpace: "pre-line" }}>
                            {` ${val}`}
                          </div>
                        </div>
                      ),
                  )}
                </td>
                <td>
                  {goal?.support_content?.achievement_time?.map(
                    (val, i) =>
                      val && (
                        <div key={i} className={"list__item"}>
                          <div className={"dot"}></div>
                          <div style={{ whiteSpace: "pre-line" }}>
                            {` ${val}`}
                          </div>
                        </div>
                      ),
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PrintSummaryGoalsContentTable>
      <PrintSummaryFooter>
        <div className={"left__container"}>
          {operationData?.display_content?.includes("3") && (
            <Typography.Paragraph
              style={{
                margin: 0,
              }}
            >
              {t(operationData?.statementText?.consentStatement)}
            </Typography.Paragraph>
          )}
          {operationData?.display_content?.includes("1") && (
            <>
              {operationData?.consent_date?.length ? (
                <>
                  {operationData?.consent_date?.includes("2") ? (
                    <>
                      <div className={"content__container explanation_date"}>
                        <div className={"content"}>
                          <div className={"content--image"}>
                            <Typography.Paragraph
                              style={{
                                margin: 0,
                              }}
                            >
                              {t("Explanation date")}
                            </Typography.Paragraph>
                            {renderSignature(data?.digital_signature_image)}
                          </div>
                        </div>
                      </div>
                      <div className={"content__container consent_date"}>
                        <div className={"content"}>
                          <div className={"content--image"}>
                            <Typography.Paragraph
                              style={{
                                margin: 0,
                              }}
                            >
                              {t("Consent date")}
                            </Typography.Paragraph>
                            {renderSignature(
                              data?.consent_date_digital_signature_image,
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={"content__container"}>
                      <div id={"plan__date"}>
                        <Typography.Text>
                          {t("Explanation date")}
                        </Typography.Text>
                        <div className={"date--info"}>
                          <span>{getCurrentJapaneseEra()}</span>
                          <span>{t("Year")}</span>
                          <span>{t("Month")}</span>
                          <span>{t("Day")}</span>
                        </div>
                      </div>
                      <div id={"plan__date"}>
                        <Typography.Text>{t("Consent date")}</Typography.Text>
                        <div className={"date--info"}>
                          <span>{getCurrentJapaneseEra()}</span>
                          <span>{t("Year")}</span>
                          <span>{t("Month")}</span>
                          <span>{t("Day")}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={"content__container"}>
                    <div className={"content"}>
                      <div className={"content--image"}>
                        <Typography.Paragraph
                          style={{
                            margin: 0,
                          }}
                        >
                          {t("Explanation date")}
                        </Typography.Paragraph>
                        {renderSignature(
                          data?.explanation_date_digital_signature_image,
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={"content__container"}>
                  <div id={"plan__date"}>
                    <Typography.Text>{t("Consent date")}</Typography.Text>
                    <div className={"date--info"}>
                      <span>{getCurrentJapaneseEra()}</span>
                      <span>{t("Year")}</span>
                      <span>{t("Month")}</span>
                      <span>{t("Day")}</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {operationData?.display_content?.includes("4") && (
            <div className={"content__container child"}>
              <div className={"content"}>
                <div className={"content--image"}>
                  <Typography.Paragraph
                    style={{
                      margin: 0,
                    }}
                  >
                    {t("User name")}
                  </Typography.Paragraph>
                  {renderSignature(data?.user_digital_signature_image)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={"right__container"}>
          <div className={"right__container--content"}>
            {operationData?.parent_sign?.includes("1") && (
              <Typography.Paragraph
                style={{
                  margin: 0,
                }}
              >
                {t(operationData?.statementText?.consentStatementForChild)}
              </Typography.Paragraph>
            )}

            <div>{data?.facility?.facility_name}</div>
            <div className={"content--support"}>
              <div className={"sign__field"}>
                <div>{t("Adult development support manager")}</div>
                {operationData?.display_content?.includes("8") ? (
                  <>{renderSignature(data?.manager_digital_signature_image)}</>
                ) : (
                  <>
                    <p className={"user-dev-support-manager"}>
                      {data?.staff?.staff_name ? data?.staff?.staff_name : ""}{" "}
                    </p>
                  </>
                )}
              </div>
              {operationData?.display_content?.includes("7") && (
                <div>{t("mark")}</div>
              )}
            </div>
          </div>
        </div>
      </PrintSummaryFooter>
    </StyledPrintPageWrapper>
  )
}

export default forwardRef(PlanPrintPage)
