import { API, removeBlankAttributes } from "@project/common"
import {
  AttendanceRecUpdateReqPayload,
  OneDailyRecordRequest,
  UserServiceContentResultWithAdditionalData,
} from "../types"

export const createUserDailyRecord = (values: any) => {
  return API.post(`/adult-user/daily-record`, values)
}

export const updateUserDailyRecord = (values: any) => {
  return API.put(`/adult-user/daily-record/${values?.id}`, values)
}

export const getAllUserDailyRecord = ({
  year,
  month,
}: {
  year: number
  month: number
}) => {
  const params = removeBlankAttributes({ year, month })
  return API.get(`/adult-user/daily-record`, { params })
}

export const createUserDailyRecordRequest = (
  values: Partial<AttendanceRecUpdateReqPayload>,
) => {
  return API.post(`/adult-user/daily-record/request`, values)
}

export const getUserDailyRecordRequest = ({
  year,
  month,
  page,
  pageSize,
  request_type = 1,
}: {
  year: number
  month: number
  page: number
  pageSize: number | "Infinity"
  request_type: number
}): Promise<OneDailyRecordRequest> => {
  const params = removeBlankAttributes({ year, month, request_type })
  return API.get(
    `/adult-user/daily-record/request?page=${page}&pageSize=${pageSize}`,
    {
      params,
    },
  )
}

export const updateUserDailyRecordRequest = ({
  values,
  id,
  requestTo,
}: {
  values: Partial<AttendanceRecUpdateReqPayload>
  id: string
  requestTo?: string
}) => {
  if (requestTo && requestTo === "re-request") {
    return API.put(`/adult-user/daily-record/re-request/${id}`, values)
  }
  return API.put(`/adult-user/daily-record/request/${id}`, values)
}

export const cancelDailyRecordRequest = (id: number) => {
  return API.delete(`/adult-user/daily-record/request/${id}`)
}

export type AttendanceRecordData = {
  data: UserServiceContentResultWithAdditionalData[]
}
export const GetAdultMonthlyAttendance = ({
  year,
  month,
}: {
  year: number
  month: number
}): Promise<AttendanceRecordData> =>
  API.get(`/adult-user/my-monthly-attendance/${year}/${month}`)

export const fetchWorkMasterData = () => {
  return API.get(`/adult-user/work?page=1&pageSize=Infinity`)
}

export const reRequestDailyRecordRequest = (id: number) => {
  return API.put(`/adult-user/daily-record/re-request/${id}`)
}

/**
 * Delete daily record by id
 * @param {number} id The id of the daily record to delete
 * @returns {Promise<AxiosResponse>} The response from the server
 */
export const deleteUserDailyRecord = (id: number) => {
  return API.delete(`/adult-user/daily-record/${id}`)
}
