import { CloseCircleFilled, LoadingOutlined } from "@ant-design/icons"
import { Button, Upload, message, notification } from "antd"
import { RcFile } from "antd/lib/upload"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { fileUpload } from "../../services"

interface FileUploaderProps {
  onChange?: (fileName?: any, value?: any) => void
  uploadHint?: string
  isLoading?: (value: boolean) => void
  currentImage?: string
  fileName?: string
  setFileName?: React.Dispatch<any>
  setType?: React.Dispatch<any>
  inputType?: string
  disableUploadBtn?: boolean
  uploadTitle?: string
  uploadSubTitle?: string
  imageTypeLabel?: boolean
  label?: string
  acceptType?: string
}

const Wrapper = styled.div`
  .file-area {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .ant-btn {
      color: #888787;
      border: 1px solid #d2d1d1;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      width: 130px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 11px;
      .ant-space-item {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .upload-hint {
      font-size: 14px;
      font-weight: 400;
      color: #191919;
      word-break: break-all;
    }
  }
  .image_type_label {
    margin-top: 10;
    color: #191919;
    font-size: 12px;
    margin-bottom: 20px;
  }
  .upload_title {
    margin: "0px 4px";
    font-size: 14;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .display-file {
    align-items: center;
    .file-name {
      word-break: break-all;
    }
    .delete-btn {
      word-break: keep-all;
    }
  }
`
const DisplayFile = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 20px;
  }
`
export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onChange,
  currentImage,
  fileName,
  setFileName,
  isLoading,
  inputType,
  disableUploadBtn,
  uploadTitle,
  uploadSubTitle,
  imageTypeLabel = true,
  label,
  acceptType,
}) => {
  const [file, setFile] = useState(null)
  const [originalFileName, setOriginalFileName] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (currentImage != "") {
      setFile(currentImage)
    }
    setFileName && setFileName(originalFileName)
  }, [currentImage])

  const { mutate, isLoading: uploading } = useMutation(
    "fileUpload",
    fileUpload,
    {
      onSuccess: (result) => {
        isLoading(false)
        onChange && onChange(result?.data)
        setFile(result?.data)
      },
      onError: (error?: any) => {
        isLoading(false)
        const msg = error?.data?.error?.message
        notification.error({
          message: msg ? t(`${msg}`) : t("An error has occurred"),
        })
      },
    },
  )

  const handleDeleteClick = () => {
    setFile(null)
    setOriginalFileName(null)
    onChange && onChange(null, null)
  }

  const beforeUpload = (file) => {
    const isPdf = file.type == "application/pdf"
    const isWord = file.type == "application/msword"
    const acceptedImage =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/heic"
    const isExcel =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/msword" ||
      file.type === "application/pdf" ||
      file.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

    const isLt10M = file.size / 1024 / 1024 < 5
    if (!isLt10M) {
      message.error({
        content: t(
          "You can only upload JPG/PNG/GIF/HEIC/PDF/WORD/EXCEL image with less than 5MB",
        ),
        key: "37",
        icon: <CloseCircleFilled onClick={() => message.destroy("37")} />,
      })
      return false
    }
    const isAcceptedFormat = isPdf || isWord || acceptedImage || isExcel
    if (!isAcceptedFormat) {
      message.error({
        content: t("You can only upload JPG/PNG/GIF/HEIC, Word or Excel file!"),
        key: "38",
        icon: <CloseCircleFilled onClick={() => message.destroy("38")} />,
      })
    }
    return isAcceptedFormat && isLt10M
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = async (info) => {
    isLoading(true)
    if (info.file.status === "done") {
      const bodyFormData = new FormData()
      bodyFormData.append("file", info?.file?.originFileObj)
      bodyFormData.append("model", "temp")
      mutate(bodyFormData)
      setOriginalFileName(info?.file?.originFileObj?.name)
    }
  }

  return (
    <Wrapper>
      {!file && (
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          accept={acceptType || "*"}
          customRequest={({ onSuccess }) =>
            setTimeout(() => {
              onSuccess("ok", null)
            }, 0)
          }
        >
          <div className={"file-area"}>
            {inputType === "file" ? (
              <Button
                icon={
                  uploading ? (
                    <LoadingOutlined />
                  ) : disableUploadBtn ? (
                    <img
                      src={"/assets/icons/disabled-upload-image-icon.svg"}
                      alt={"icon"}
                    />
                  ) : (
                    <img
                      src={"/assets/icons/upload-image-icon.svg"}
                      alt={"icon"}
                    />
                  )
                }
                shape={"round"}
                disabled={disableUploadBtn}
                type={"primary"}
                style={{
                  height: "40px",
                  borderColor: disableUploadBtn ? "#D2D1D1" : "#0782C8",
                  backgroundColor: disableUploadBtn ? "#F3F3F3" : "#EAF9FF",
                  borderRadius: "100px",
                  color: disableUploadBtn ? "#D2D1D1" : "#000",
                  cursor: disableUploadBtn ? "not-allowed" : "pointer",
                }}
              >
                <span className={"upload_title"}>
                  {uploadTitle ? uploadTitle : t("upload")}
                </span>
              </Button>
            ) : (
              <Button
                icon={uploading ? <LoadingOutlined /> : null}
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #D2D1D1",
                  width: "130px",
                  color: "#888787",
                  height: "30px",
                  margin: "0px 4px",
                }}
                disabled={
                  disableUploadBtn ? disableUploadBtn : uploading ? true : false
                }
              >
                <span style={{ margin: "0px 4px", fontSize: 11 }}>
                  {t("Upload File")}
                </span>
              </Button>
            )}

            <p style={{ marginLeft: 10 }}>
              {uploadSubTitle ? uploadSubTitle : t("File not selected")}
            </p>
          </div>
          {disableUploadBtn ? null : (
            <span className={imageTypeLabel ? "image_type_label" : null}>
              {imageTypeLabel
                ? label
                  ? label
                  : t(
                      "(Image: jpg,gif,png,HEIC ／PDF、Word、Excel up to 10 Mb)",
                    )
                : null}
            </span>
          )}
        </Upload>
      )}
      {currentImage && (
        <DisplayFile className={"display-file"}>
          <div className={"file-name"}>{originalFileName || fileName}</div>
          <div
            className={"delete-btn"}
            onClick={handleDeleteClick}
            style={{
              textDecoration: "underline",
              color: "red",
              cursor: "pointer",
            }}
          >
            {t("Delete")}
          </div>
        </DisplayFile>
      )}
    </Wrapper>
  )
}

export { FileUploader }
